import { AnimatePresence, motion } from "framer-motion";
import { CustomButton } from "../components";
import {
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
  FaFacebook,
} from "react-icons/fa";

import {
  headContentAnimation,
  headTextAnimation,
  slideAnimation,
} from "../config/motion";
import { BiCheckCircle } from "react-icons/bi";
import { RiTruckLine } from "react-icons/ri";
import { AiOutlineGlobal } from "react-icons/ai";
import { MdVerifiedUser } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import CookiesPolicy from "../components/policies/CookiesPolicy";
import PrivacyPolicy from "../components/policies/PrivacyPolicy";
const Home = () => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedContent, setSelectedContent] = useState("cookie");

  const handlePrivacyClick = () => {
    setSelectedContent("cookie");
    setIsModalOpen(true);
  };

  const handleTermsClick = () => {
    setSelectedContent("terms");
    setIsModalOpen(true);
  };

  return (
    <AnimatePresence>
      <motion.section
        className="flex flex-col h-full overflow-y-auto overflow-x-hidden"
        {...slideAnimation("left")}
      >
        <motion.header
          className="py-6 px-6 lg:px-12 flex flex-row justify-between items-center"
          {...slideAnimation("down")}
        >
          <img
            src="./logo-site.png"
            alt="logo"
            className="h-8 py-2 object-contain"
          />
          <div className="hidden md:flex flex-row justify-center space-x-4">
            <a
              href="https://www.facebook.com/profile.php?id=100094491291666"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook className="w-5 h-5 text-gray-500 hover:text-gray-800" />
            </a>
            <a
              href="https://www.tiktok.com/@tazzu.clothing"
              target="_blank"
              rel="noreferrer"
            >
              <FaTiktok className="w-5 h-5 text-gray-500 hover:text-gray-800" />
            </a>
            <a
              href="https://www.instagram.com/tazzu.clothing/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram className="w-5 h-5 text-gray-500 hover:text-gray-800" />
            </a>

            <a
              href="https://www.linkedin.com/company/tazzu/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn className="w-5 h-5 text-gray-500 hover:text-gray-800" />
            </a>
          </div>
          <button
            onClick={() =>
              navigate("/tracking", {
                state: {},
              })
            }
            className={`justify-center rounded-md py-1.5 px-4 bg-black text-white font-bold text-md hover:scale-105 relative z-50`}
          >
            Track your order
          </button>
        </motion.header>

        <div className="flex flex-1 items-center grow">
          <motion.div
            {...headContentAnimation}
            className="grid grid-cols-5 gap-10 p-6 lg:px-12 md:py-2"
          >
            <div className="grid col-span-5 sm:col-span-3 gap-5">
              <motion.div {...headTextAnimation}>
                <h1 className="xl:text-8xl xl:leading-[7rem] leading-[4.5rem] text-6xl font-black text-black max-w-[80%] md:max-w-full relative z-10">
                  <span className="relative z-50">
                    {" "}
                    Your Taste
                    <br className="block" />
                    Your Style.
                  </span>

                  <div className="md:hidden max-w-full flex items-center absolute top-0 bottom-0 m-auto right-[-40%] z-10">
                    <div className="w-full aspect-square relative">
                      <div className="absolute top-0 bottom-0 left-0 right-0 m-auto w-[95%] h-[95%] outline outline-[20px] outline-white z-10"></div>
                      <video autoPlay loop muted className="w-full -z-50">
                        <source
                          src="/videos/SpinningShirt.webm"
                          type="video/webm"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </h1>
              </motion.div>
              <p className="max-w-2xl font-normal text-gray-600 text-base mt-4 relative z-20">
                Welcome to our fashion playground where{" "}
                <strong>you are the designer</strong>! With our nifty{" "}
                <strong>AI</strong> tool, you can create an{" "}
                <strong>exclusive</strong>, <strong>high-quality</strong> shirt
                or hoodie that <strong>no one else</strong> in the world has.
                Plus, our superior stitching and fabric guarantees that your
                design will stand the test of time. So,{" "}
                <strong>go wild!</strong> The possibilities here are endless and
                so is the <strong>fun</strong> -{" "}
                <strong>the sky is the limit!</strong>
              </p>

              <div className="flex flex-row gap-10 mb-4">
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <BiCheckCircle className="w-4 h-4 text-gray-600" />
                    <p className="text-sm text-gray-600">Best Quality</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <MdVerifiedUser className="w-4 h-4 text-gray-600" />
                    <p className="text-sm text-gray-600">100% Original</p>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <RiTruckLine className="w-4 h-4 text-gray-600" />
                    <p className="text-sm text-gray-600">Fast Delivery</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <AiOutlineGlobal className="w-4 h-4 text-gray-600" />
                    <p className="text-sm text-gray-600">Worldwide Delivery</p>
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center justify-between">
                <CustomButton
                  type="filled"
                  title="Customize It"
                  to="/customizer"
                  customStyles="w-fit px-4 py-2.5 my-auto font-bold text-sm hover:scale-105"
                />
                <div className="md:hidden flex justify-center space-x-4">
                  <a
                    href="https://www.facebook.com/profile.php?id=100094491291666"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaFacebook className="w-5 h-5 text-gray-500 hover:text-gray-800" />
                  </a>
                  <a
                    href="https://www.instagram.com/tazzu.clothing/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaInstagram className="w-5 h-5 text-gray-500 hover:text-gray-800" />
                  </a>
                  <a
                    href="https://www.tiktok.com/@tazzu.clothing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaTiktok className="w-5 h-5 text-gray-500 hover:text-gray-800" />
                  </a>

                  <a
                    href="https://www.linkedin.com/company/tazzu/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaLinkedinIn className="w-5 h-5 text-gray-500 hover:text-gray-800" />
                  </a>
                </div>
              </div>
            </div>

            <div className="hidden md:block col-start-2 col-span-3 md:col-span-2 md:col-start-4 overflow-hidden">
              <div className="w-full aspect-square relative">
                <div className="absolute top-[2.5%] left-[2.5%] w-[95%] h-[95%] outline outline-[200px] outline-white">
                  {/* Green div */}
                </div>
                <video autoPlay loop muted className="w-full">
                  <source src="/videos/SpinningShirt.webm" type="video/webm" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            {/* Modal */}
            <div className="col-span-5 text-xs cursor-pointer text-gray-500 hover:text-gray-800">
              <span onClick={() => setIsModalOpen(true)}>
                Cookies Policy / Terms of Service
              </span>
            </div>
            {/* Modal */}
            {isModalOpen && (
              <div className="fixed z-50 inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center" onClick={(e) => { e.stopPropagation(); setIsModalOpen(false) }}>
                <div className="bg-white p-8 rounded-lg max-h-[80%] max-w-[90%] overflow-y-auto" onClick={(e) => e.stopPropagation()}>
                  <div className="flex mb-4">
                    <button
                      className={`flex-1 text-center py-2 px-4 rounded-md ${selectedContent === "cookie"
                        ? "bg-gray-800 text-white"
                        : "bg-gray-200"
                        }`}
                      onClick={handlePrivacyClick}
                    >
                      Cookies Policy
                    </button>
                    <button
                      className={`flex-1 text-center py-2 px-4 rounded-md ${selectedContent === "terms"
                        ? "bg-gray-800 text-white"
                        : "bg-gray-200"
                        }`}
                      onClick={handleTermsClick}
                    >
                      Terms of Service
                    </button>
                  </div>
                  <h2 className="text-xl font-bold mb-4">
                    {selectedContent === "cookie"
                      ? "Cookies Policy"
                      : "Terms of Service / Privacy Policy"}
                  </h2>
                  <div className="grid grid-cols-1">
                    {selectedContent === "cookie" ? (
                      <CookiesPolicy></CookiesPolicy>
                    ) : (
                      <PrivacyPolicy></PrivacyPolicy>
                    )}
                  </div>
                  <button
                    className="mt-4 bg-gray-800 text-white py-2 px-4 rounded-md hover:bg-gray-700"
                    onClick={(e) => { e.stopPropagation(); setIsModalOpen(false) }}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </motion.div>
        </div>
        {/**
        <motion.div className="py-5 px-12 flex justify-center space-x-4">
          <a
            href="https://www.instagram.com/tazzu.clothing/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="w-5 h-5 text-gray-500 hover:text-gray-800" />
          </a>
          <a
            href="https://www.tiktok.com/@tazzu.clothing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTiktok className="w-5 h-5 text-gray-500 hover:text-gray-800" />
          </a>
           * 
           * 
          <a
            href="https://www.youtube.com/tazzu.clothing/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube className="w-5 h-5 text-gray-500 hover:text-gray-800" />
          </a>
           <a
            href="https://www.facebook.com/tazzu.clothing/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF className="w-5 h-5 text-gray-500 hover:text-gray-800" />
          </a>
          <a
            href="https://www.twitter.com/tazzu.clothing/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="w-5 h-5 text-gray-500 hover:text-gray-800" />
          </a>
        </motion.div>
           */}
      </motion.section>
    </AnimatePresence>
  );
};

export default Home;
