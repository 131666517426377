import React from "react";
import { trackingStates } from "../../config/utils";
import { AiFillCheckCircle, AiOutlineLoading } from 'react-icons/ai';
import { BsCircle } from 'react-icons/bs';

const ShipmentProgress = ({trackingStateIndex}) => {

    return (
        <div className="flex md:flex-row w-full flex-wrap md:flex-nowrap gap-4 py-4 max-w-lg mb-4">
            {trackingStates.map((state, index) => {
                return (
                    <div key={index} className="flex flex-col w-full">
                        <div className={`${(index < trackingStateIndex || trackingStateIndex === 3) ? 'bg-green-600' : 'bg-slate-300'} rounded-md h-1 mb-2 hidden md:block `}>
                            {index === trackingStateIndex ? <div className="w-1/2 h-1 bg-green-600 z-2"></div> : ''}
                        </div>
                        <div className="flex flex-row items-center gap-2 text-base">
                            {
                                (index < trackingStateIndex || trackingStateIndex === 3) ? <AiFillCheckCircle color='green'></AiFillCheckCircle> :
                                    index === trackingStateIndex ?
                                        <div>
                                            <AiOutlineLoading className="z-2 absolute" color="blue"></AiOutlineLoading>
                                            <BsCircle color="grey" className="z-0"></BsCircle>
                                        </div> :
                                        <BsCircle color="grey"></BsCircle>

                            }
                            <span className={`${index === trackingStateIndex ? 'text-black' : 'text-slate-600'}`}>{state}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ShipmentProgress;
