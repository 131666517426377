import { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";

const InfoIconWithTooltip = ({selectedType}) => {
  const [isHovering, setIsHovering] = useState(false);

  const thStyle = {
    fontFamily: "Inter var, sans-serif",
    fontSize: "14px",
    fontWeight: "normal",
    overflow: "hidden",
    paddingTop: "2px",
    paddingBottom: "2px",
    textAlign: "left",
    verticalAlign: "top",
    textWrap: "nowrap",
  };

  return (
    <div
      className="relative inline-block z-30"
      onClick={() => setIsHovering(!isHovering)}
    >
      <FaInfoCircle
        className=" h-5 w-5 md:h-6 md:w-6 mb-4 md:mb-3 text-slate-600 ml-2 cursor-pointer"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      />
      {isHovering && (
        <div className="absolute top-6 left-2 mt-2 bg-white border p-2 shadow-lg text-sm rounded-lg">
          <p style={{marginBottom: 4}}>Price breakdown:</p> 
          <div className="flex items-center">
            <table>
              <thead>
                <tr>
                  <th style={thStyle}>{selectedType}</th>
                  <th style={{ ...thStyle, paddingLeft: 30, textAlign: 'right'}}>24,99 €</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ ...thStyle, borderBottomWidth: 1, borderBottomColor: "lightgrey"}}>Shipping</td>
                  <td style={{ ...thStyle, paddingLeft: 30, borderBottomWidth: 1, borderBottomColor: "lightgrey", textAlign: 'right'}}>5,00 €</td>
                </tr>
                <tr>
                  <td style={{ ...thStyle, paddingTop: 5}}>Total</td>
                  <td style={{ ...thStyle, paddingTop: 5, paddingLeft:30, textAlign: 'right' }}>29,99 €</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoIconWithTooltip;
