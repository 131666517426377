import { Canvas } from "@react-three/fiber";
import { Preload } from "@react-three/drei";
import Shirt from "./Shirt";
import ToteBag from "./ToteBag";
import Hoodie from "./Hoodie";
import React, { Suspense } from "react";
import { Stage } from "@react-three/drei";
import { OrbitControls } from "./OrbitControls";
import ReactLoading from "react-loading";

const CanvasModel = ({ cloth, color, image, stamp }) => {

  return (
    <Suspense
      fallback={
        <div className="flex flex-1 w-full items-center justify-center">
          <ReactLoading type="spin" color="black" height={64} width={64} />
        </div>
      }
    >
      {cloth === "T-Shirt" && (
        <Canvas className="canvas" shadows camera={{ fov: 25, zoom: 0.8 }}>
          <Preload all />
          <Stage preset="rembrandt" intensity={1}>
            <Shirt color={color} image={image} stamp={stamp} />
          </Stage>
          <OrbitControls
            autoRotateSpeed={-2}
            dampingFactor={0.25}
            enableDamping
            enableZoom={false}
            minPolarAngle={90 * (Math.PI / 180)}
            maxPolarAngle={90 * (Math.PI / 180)}
            enablePan={false}
          />
        </Canvas>
      )}

      {cloth === "Hoodie" && (
        <Canvas className="canvas" shadows camera={{ fov: 25, zoom: 0.8 }}>
          <Preload all />
          <Stage preset="rembrandt" intensity={1}>
            <Hoodie color={color} image={image} stamp={stamp} />
          </Stage>
          <OrbitControls
            autoRotateSpeed={-2}
            dampingFactor={0.25}
            enableDamping
            enableZoom={false}
            minPolarAngle={90 * (Math.PI / 180)}
            maxPolarAngle={90 * (Math.PI / 180)}
            enablePan={false}
          />
        </Canvas>
      )}
      {cloth === "Tote-bag" && (
        <Canvas className="canvas" shadows camera={{ fov: 25, zoom: 0.8 }}>
          <Preload all />
          <Stage preset="rembrandt" intensity={1}>
            <ToteBag color={color} image={image} stamp={stamp} />
          </Stage>
          <OrbitControls
            autoRotateSpeed={-2}
            dampingFactor={0.25}
            enableDamping
            enableZoom={false}
            minPolarAngle={90 * (Math.PI / 180)}
            maxPolarAngle={90 * (Math.PI / 180)}
            enablePan={false}
          />
        </Canvas>
      )}
    </Suspense>
  );
};

export default CanvasModel;
