import React, { useEffect, useState } from "react";
import { Decal, useGLTF } from "@react-three/drei";
import { TextureLoader } from "three";

const Hoodie = ({ color, stamp }) => {
  //const { nodes, materials } = useGLTF("/shirt_baked.glb");
  const { nodes, materials } = useGLTF("/hoodie-black.glb");
  const [logoTexture, setLogoTexture] = useState(null);
  const [frontTexture, setFrontTexture] = useState(null);
  const [backTexture, setBackTexture] = useState(null);

  useEffect(() => {
    const loadTexture = (path, textureStateSetter) => {
      if (path !== "") {
        const loader = new TextureLoader();
        loader.load(path, (texture) => {
          const aspectRatio = texture.image.width / texture.image.height;

          textureStateSetter({
            texture: texture,
            aspectRatio: aspectRatio,
            scale:
              aspectRatio > 1 ? [1, 1 / aspectRatio, 1] : [aspectRatio, 1, 1],
          });
        });
      } else {
        textureStateSetter(null);
      }
    };

    loadTexture(stamp.logo, setLogoTexture);
    loadTexture(stamp.front, setFrontTexture);
    loadTexture(stamp.back, setBackTexture);
  }, [stamp]);

  return (
    <group dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Object_2.geometry}
          material={materials.CORDON_FRONT_5197366}
          material-roughness={1}
        >
          <meshStandardMaterial
            attach="material"
            color={color}
            depthTest={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Object_3.geometry}
          material={materials.HOODIE_FRONT_5197361}
        >
          <meshStandardMaterial
            attach="material"
            color={color}
            depthTest={true}
          />
          {frontTexture && (
            <Decal
              position={[-20, -60, 1270]} //front
              rotation={[Math.PI / 2, 0, 0]}
              scale={[
                150 * frontTexture.scale[0],
                150 * frontTexture.scale[1],
                150,
              ]}
              map={frontTexture.texture}
              depthTest={true}
              depthWrite={true}
            />
          )}
          {backTexture && (
            <Decal
              position={[0, 230, 1310]} //back
              rotation={[Math.PI / 2, Math.PI, 0]}
              scale={[
                200 * backTexture.scale[0],
                200 * backTexture.scale[1],
                200,
              ]}
              map={backTexture.texture}
              depthTest={true}
              depthWrite={true}
            />
          )}
          {logoTexture && (
            <Decal
              position={[50, -44, 1390]} //logo
              rotation={[Math.PI / 2, 0, 0]}
              scale={[80 * logoTexture.scale[0], 80 * logoTexture.scale[1], 80]}
              map={logoTexture.texture}
              depthTest={true}
              depthWrite={true}
            />
          )}
        </mesh>
        <mesh
          geometry={nodes.Object_4.geometry}
          material={materials.HOODIE_FRONT_5197361}
          material-roughness={1000}
          depthTest={true}
        >
          <meshStandardMaterial
            attach="material"
            color={color}
            depthTest={true}
          />
        </mesh>
        <mesh
          geometry={nodes.Object_5.geometry}
          material={materials.HOODIE_FRONT_5197361}
          material-roughness={1000}
          depthTest={true}
        >
          <meshStandardMaterial
            attach="material"
            color={color}
            depthTest={true}
          />
          {backTexture && (
            <Decal
              position={[0, 85, 1310]} //back
              rotation={[Math.PI / 2, Math.PI, 0]}
              scale={[
                200 * backTexture.scale[0],
                200 * backTexture.scale[1],
                200,
              ]}
              map={backTexture.texture}
              depthTest={true}
              depthWrite={true}
            />
          )}
          {frontTexture && (
            <Decal
              position={[-20, -50, 1270]} //front
              rotation={[Math.PI / 2, 0, 0]}
              scale={[
                150 * frontTexture.scale[0],
                150 * frontTexture.scale[1],
                150,
              ]}
              map={frontTexture.texture}
              depthTest={true}
              depthWrite={true}
            />
          )}
        </mesh>
      </group>
    </group>
  );
};

export default Hoodie;
