import React, { useEffect, useState } from 'react';
import { useElements } from '@stripe/react-stripe-js';

const ExpressCheckoutButton = ({ confirmPayment, setIsProcessing, handleResize }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const elements = useElements();

  const onClick = ({ resolve }) => {
    const options = {
      emailRequired: true,
      shippingAddressRequired: true,
      shippingRates: [
        {
          id: 'free_shipping',
          amount: 0,
          displayName: 'Free Shipping'
        }
      ]
    };
    resolve(options);
  };

  const onConfirm = async (event) => {
    setIsProcessing(true);

    const cardOwner = event.billingDetails.name;
    const name = event.shippingAddress.name;
    const email = event.billingDetails.email;
    await confirmPayment(name, email, event.shippingAddress.address, cardOwner);

    setIsProcessing(false);
  };

  const options = {
    paymentMethodOrder: ['paypal', 'apple_pay', 'google_pay'],
    wallets: {
      applePay: 'always',
      googlePay: 'always'
    }
  }

  useEffect(() => {
    if (elements && !elements.getElement('expressCheckout')) {
      const expressCheckoutElement = elements.create('expressCheckout', options);
      expressCheckoutElement.mount('#express-checkout-element');
      expressCheckoutElement.on('click', onClick);
      expressCheckoutElement.on('confirm', onConfirm);
      expressCheckoutElement.on('ready', () => { handleResize(); setIsLoaded(true); });
    }
  }, [elements]);

  return (
    <>
      <div id="checkout-page">
        <div id='express-checkout-element'></div>
        {/* {

          <ExpressCheckoutElement
          onClick={onClick}
          onConfirm={onConfirm}
          onReady={() => { 
            handleResize(); 
            setIsLoaded(true) }}
          options={{
            paymentMethodOrder: ['paypal', 'apple_pay', 'google_pay'],
            wallets: {
              applePay: 'always',
              googlePay: 'always'
            }
          }}
          />
        
        } */}
      </div>

      {isLoaded &&
        <div className="flex items-center relative my-[16px] pt-[16px] pb-[32px]">
          <hr className="w-full"></hr>
          <p className="absolute left-[50%] translate-x-[-50%] text-sm text-gray-400 bg-white whitespace-nowrap px-4">Or pay with card</p>
        </div>
      }
    </>
  );
};

export default ExpressCheckoutButton;