import React, { useEffect, useState } from "react";
import { Decal, useGLTF } from "@react-three/drei";
import { TextureLoader } from "three";

const ToteBag = ({ color, stamp }) => {
  const { nodes, materials } = useGLTF("/ToteBag3DModel.glb");
  const [logoTexture, setLogoTexture] = useState(null);
  const [frontTexture, setFrontTexture] = useState(null);

  useEffect(() => {
    const loadTexture = (path, textureStateSetter) => {
      if (path !== "") {
        const loader = new TextureLoader();
        loader.load(path, (texture) => {
          texture.anisotropy = 49;
          const aspectRatio = texture.image.width / texture.image.height;

          textureStateSetter({
            texture: texture,
            aspectRatio: aspectRatio,
            scale:
              aspectRatio > 1 ? [1, 1 / aspectRatio, 1] : [aspectRatio, 1, 1],
          });
        });
      } else {
        textureStateSetter(null);
      }
    };

    loadTexture(stamp.logo, setLogoTexture);
    loadTexture(stamp.front, setFrontTexture);
  }, [stamp]);

  return (
    <group dispose={null}>
      <group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.canvas_tote_bag_1488.geometry}
          material={materials.canvas_tote_bag_1488}
          dispose={null}
        >
          <meshStandardMaterial
            attach="material"
            color={color}
            depthTest={true}
          />
          {frontTexture && (
            <Decal
              position={[0, 18.0, 1.15]} //front
              rotation={[0, 0, 0]}
              scale={[
                21.85 * frontTexture.scale[0],
                21.85 * frontTexture.scale[1],
                2.0,
              ]}
              map={frontTexture.texture}
              depthTest={true}
              depthWrite={true}
            />
          )}
          {logoTexture && (
            <Decal
              position={[0, 4.0, 1.15]} //front
              rotation={[0, 0, 0]}
              scale={[
                3.0 * logoTexture.scale[0],
                3.0 * logoTexture.scale[1],
                2.0,
              ]}
              map={logoTexture.texture}
              depthTest={true}
              depthWrite={true}
            />
          )}
        </mesh>
      </group>
    </group>
  );
};

useGLTF.preload("/ToteBag3DModel.glb");

export default ToteBag;
