import { AnimatePresence, motion } from "framer-motion";
import { fadeAnimation } from "../config/motion";
import { useEffect, useState, useCallback } from "react";
import { Header, TrackingInfo, TrackingInput } from "../components";
import { useLocation } from "react-router-dom"; // Import useLocation
import axios from "axios";

// This function parses the query parameters
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Tracking = () => {
  const [trackingNumber, setTrackingNumber] = useState("");
  const [showTrackingInfo, setShowTrackingInfo] = useState(false);
  const [trackingStateIndex, setTrackingStateIndex] = useState(2);
  const [savedTrackingNumbers, setSavedTrackingNumbers] = useState([]);
  const [order, setOrder] = useState(null);

  const query = useQuery();

  /*
  useEffect(() => {
    if (process.env.NODE_ENV === 'production')
      logEvent(analytics, 'screen_view', {
        firebase_screen: 'Tracking',
      });
  }, []);
  */

  const trackOrder = useCallback(async (number) => {
    const value = number ? number : trackingNumber;
    const response = await axios.get(
      (process.env.NODE_ENV === "production" && window.location.origin === "https://tazzu.net")
        ? `https://us-central1-aiclothing.cloudfunctions.net/app/order?trackingID=${value}`
        : `http://localhost:5001/aiclothing/us-central1/app/order?trackingID=${value}`
    );

    if (response.status === 200) {
      setOrder(response.data.order);
      setTrackingStateIndex(
        response.data.order.state === "success"
          ? 3
          : response.data.order.state === "sent"
            ? 2
            : response.data.order.state === "picked"
              ? 1
              : response.data.order.state === "new"
                ? 0
                : response.data.order.successDate
                  ? 3
                  : response.data.order.sentDate
                    ? 2
                    : response.data.order.pickedDate
                      ? 1
                      : response.data.order.timestamp
                        ? 0
                        : -1
      );
      setShowTrackingInfo(true);

      if (
        Array.isArray(savedTrackingNumbers) &&
        savedTrackingNumbers.length > 0
      ) {
        if (savedTrackingNumbers.indexOf(value) < 0) {
          localStorage.setItem(
            "trackingNumber",
            JSON.stringify([...savedTrackingNumbers, value])
          );
          setSavedTrackingNumbers((prevState) => [...prevState, value]);
        }
      } else {
        localStorage.setItem("trackingNumber", JSON.stringify([value]));
        setSavedTrackingNumbers([value]);
      }
    } else if (response.status === 404) {
      alert("Order not found. Please verify your tracking id and try again.");
      setTrackingNumber("");
    } else {
      alert("Error fetching order. Please try again later.");
      setTrackingNumber("");
    }
  }, [savedTrackingNumbers, trackingNumber]);

  useEffect(() => {
    // Retrieve trackingNumber from local storage on component mount
    const trackingNumbers = JSON.parse(localStorage.getItem("trackingNumber"));
    if (trackingNumbers && Array.isArray(trackingNumbers)) {
      setSavedTrackingNumbers(trackingNumbers);
    }
    // If the URL query contains an order id, track it
    const orderId = query.get("order_id");
    if (orderId) {
      setTrackingNumber(orderId);
      trackOrder(orderId);
    }

  }, []);

  const handleTextChange = (event) => {
    setTrackingNumber(event.target.value);
  };

  return (
    <AnimatePresence>
      <motion.div {...fadeAnimation} className="flex flex-1 flex-col h-full bg-slate-300 overflow-y-scroll">
        <Header />
        <div className="mt-[80px] flex flex-1 flex-col justify-center">
          {showTrackingInfo ? (
            <TrackingInfo
              trackingNumber={trackingNumber}
              trackingStateIndex={trackingStateIndex}
              order={order}
            />
          ) : (
            <TrackingInput
              trackingNumber={trackingNumber}
              handleTextChange={handleTextChange}
              trackOrder={trackOrder}
              numbers={savedTrackingNumbers}
              setSavedTrackingNumbers={setSavedTrackingNumbers}
              setTrackingNumber={setTrackingNumber}
            />
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Tracking;
