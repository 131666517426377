import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  GoToPayment,
  CustomizerOptions,
  GoBackButton,
  PromptInput,
  Images,
  Modal,
  InfoSizes,
} from "../components";
import { fadeAnimation } from "../config/motion";
import {
  colorOptions,
  convertColor,
  findColorIndex,
  typeOptions,
} from "../config/utils"
import CanvasModel from "../canvas";

const Customizer = () => {
  const { state } = useLocation();
  let selectedTypeState,
    selectedSizeState,
    selectedColorState,
    stampState,
    imagesState,
    indexState;

  if (state) {
    ({
      selectedTypeState,
      selectedSizeState,
      selectedColorState,
      stampState,
      imagesState,
      indexState,
    } = state);
  }

  const [selectedColor, setSelectedColor] = useState(
    selectedColorState || "rgb(0, 0, 0)"
  );
  const [selectedSize, setSelectedSize] = useState(selectedSizeState || "M");
  const [selectedType, setSelectedType] = useState(
    selectedTypeState || "T-Shirt"
  );
  const [images, setImages] = useState(
    imagesState || [{ url: "/logo-white.png", title: "Tazzu Logo" }]
  );
  const [stamp, setStamp] = useState(
    stampState || { logo: images[0].url, front: "", back: "" }
  );
  const [selectedImageIndex, setSelectedImageIndex] = useState(indexState || 0);
  const [showModal, setShowModal] = useState(null);
  const [removeBgLoading, setRemoveBgLoading] = useState(false);
  const [showSizeGuide, setShowSizeGuide] = useState(false);

  const isMobile = window.innerWidth <= 600;

  /*
  useEffect(() => {
    if (process.env.NODE_ENV === 'production'){
      logEvent(analytics, 'screen_view', {
        firebase_screen: 'Customizer',
      });
    }
  }, []);
  */

  useEffect(() => {
    const currentColor = convertColor(selectedColor);
    const logoImage = images[0];

    if (
      (currentColor === "white" && logoImage.url === "/logo-white.png") ||
      (currentColor === "black" && logoImage.url === "/logo-black.png")
    ) {
      updateImagesAndStamp(currentColor);
    }
  }, [selectedColor, images]);

  const handleColorSelection = (color) => {
    setSelectedColor((prevColor) =>
      prevColor === color ? "rgb(0, 0, 0)" : color
    );
    if (
      !colorOptions[selectedType][
        findColorIndex(selectedType, color)
      ].sizes.includes(selectedSize)
    ) {
      setSelectedSize(
        colorOptions[selectedType][findColorIndex(selectedType, color)].sizes[0]
      );
    }
  };

  const updateImagesAndStamp = (color) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[0] =
        color === "white"
          ? { url: "/logo-black.png", title: "Tazzu Logo" }
          : { url: "/logo-white.png", title: "Tazzu Logo" };
      return updatedImages;
    });

    setStamp((prevStamp) => {
      const updatedStamp = { ...prevStamp };
      for (const key in updatedStamp) {
        if (updatedStamp[key] === `/logo-${color}.png`) {
          updatedStamp[key] = `/logo-${color === "white" ? "black" : "white"
            }.png`;
        }
      }
      return updatedStamp;
    });
  };

  const toggleStamp = (side) => {
    setStamp((prevState) => {
      const updatedStamp = { ...prevState };
      if (updatedStamp[side] === images[selectedImageIndex].url) {
        updatedStamp[side] = "";
      } else {
        updatedStamp[side] = images[selectedImageIndex].url;
      }
      return updatedStamp;
    });
  };

  const handleAddImageFromDevice = (image_url) => {
    setImages((prevState) => [...prevState, { url: image_url, title: "" }]);
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleChangeType = (newType) => {
    if (
      newType === "Hoodie" &&
      colorOptions["Hoodie"].filter((item) => item.color === selectedColor)
        .length === 0
    ) {
      setSelectedColor("rgb(0, 0, 0)");
    }
    if (newType === "Tote-bag") {
      setSelectedColor("rgb(232, 218, 205)");
      setStamp((prevState) => {
        return { ...prevState, logo: images[0].url };
      });
    } else {
      if (colorOptions[newType][findColorIndex(newType, newType === "Hoodie" ? "rgb(0, 0, 0)" : selectedColor)].sizes && 
            !colorOptions[newType][findColorIndex(newType, newType === "Hoodie" ? "rgb(0, 0, 0)" : selectedColor)].sizes.includes(selectedSize)) 
      {
        setSelectedSize(
          colorOptions[newType][findColorIndex(newType, newType === "Hoodie" ? "rgb(0, 0, 0)" : selectedColor)].sizes[0]
        );
      }
    }
    setSelectedType(newType);
  };

  const updateImageAtIndex = (index, newImage) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[index] = newImage;
      return updatedImages;
    });
    setStamp((prevStamp) => {
      const updatedStamp = { ...prevStamp };
      for (const key in updatedStamp) {
        if (updatedStamp[key] === newImage.otherUrl) {
          updatedStamp[key] = newImage.url;
        }
      }
      return updatedStamp;
    });
  };

  return (
    <div className="flex flex-col h-full w-full items-center overflow-y-scroll">
      <GoBackButton selectedColor={selectedColor} />

      {isMobile ? (
        <div className="absolute top-5 right-5 flex flex-col gap-2 z-20">
          <div className="flex flex-row items-start gap-4 ml-auto">
            <CustomizerOptions
              selectionType="type"
              selectionValue={selectedType}
              options={typeOptions}
              handleOptionSelection={handleChangeType}
              selectedColor={selectedColor}
            />
            <CustomizerOptions
              selectionType="color"
              selectionValue={selectedColor}
              options={colorOptions[selectedType]}
              handleOptionSelection={handleColorSelection}
              selectedColor={selectedColor}
            />
            <CustomizerOptions
              selectionType="size"
              selectionValue={selectedSize}
              options={
                colorOptions[selectedType][
                  findColorIndex(selectedType, selectedColor)
                ].sizes
              }
              handleOptionSelection={setSelectedSize}
              selectedColor={selectedColor}
              setShowSizeGuide={setShowSizeGuide}
            />
          </div>
        </div>
      ) : (
        <motion.div
          className="flex flex-col items-start gap-3 absolute -translate-y-2/4 z-10 left-5 top-2/4"
          {...fadeAnimation}
        >
          <CustomizerOptions
            selectionType="type"
            selectionValue={selectedType}
            options={typeOptions}
            handleOptionSelection={handleChangeType}
            selectedColor={selectedColor}
          />
          <CustomizerOptions
            selectionType="color"
            selectionValue={selectedColor}
            options={colorOptions[selectedType]}
            handleOptionSelection={handleColorSelection}
            selectedColor={selectedColor}
          />
          {selectedType !== "Tote-bag" && (
            <CustomizerOptions
              selectionType="size"
              selectionValue={selectedSize}
              options={
                colorOptions[selectedType][
                  findColorIndex(selectedType, selectedColor)
                ].sizes
              }
              handleOptionSelection={setSelectedSize}
              selectedColor={selectedColor}
              setShowSizeGuide={setShowSizeGuide}
            />
          )}
        </motion.div>
      )}

      <Images
        handleAddImageFromDevice={handleAddImageFromDevice}
        handleImageClick={handleImageClick}
        selectedImage={selectedImageIndex}
        images={images}
        setShowModal={setShowModal}
        isLoading={removeBgLoading ? showModal : null}
      />

      {showModal !== null && (
        <Modal
          image={images[showModal]}
          index={showModal}
          selectedColor={selectedColor}
          closeModal={() => setShowModal(null)}
          updateImage={updateImageAtIndex}
          removeBgLoading={removeBgLoading}
          setRemoveBgLoading={setRemoveBgLoading}
          selectedType={selectedType}
          stamp={stamp}
          images={images}
          selectedImageIndex={selectedImageIndex}
          toggleStamp={toggleStamp}
        />
      )}

      {showSizeGuide && (
        <InfoSizes
          selectedType={selectedType}
          setShowSizeGuide={setShowSizeGuide}
        />
      )}

      {isMobile ? (
        <div className="flex flex-col items-center max-h-full h-full w-full">
          <div className="canvas w-full flex flex-1 items-center justify-center">
            <CanvasModel
              cloth={selectedType}
              color={selectedColor}
              stamp={stamp}
            />
          </div>
          <>
            <PromptInput selectedColor={selectedColor} setImages={setImages} />
            <GoToPayment
              selectedColor={selectedColor}
              selectedType={selectedType}
              selectedSize={selectedSize}
              stamp={stamp}
              images={images}
              index={selectedImageIndex}
            ></GoToPayment>
          </>
        </div>
      ) : (
        <div className="flex flex-col w-full h-full items-center">
          <GoToPayment
            selectedColor={selectedColor}
            selectedType={selectedType}
            selectedSize={selectedSize}
            stamp={stamp}
            images={images}
            index={selectedImageIndex}
          ></GoToPayment>

          <div className="flex p-6 h-full w-full items-center">
            <CanvasModel
              cloth={selectedType}
              color={selectedColor}
              stamp={stamp}
            />
          </div>
          <PromptInput selectedColor={selectedColor} setImages={setImages} />
        </div>
      )}
    </div>
  );
};

export default Customizer;
