import { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";

const InfoIconClothing = ({ type }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className="relative inline-block  "
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <FaInfoCircle className="h-4 z-20 w-4 md:h-4 md:w-4 text-slate-400  cursor-pointer bg-white rounded-full" />
      {isHovering && (
        <div className="absolute z-30  mr-8 mt-2 bg-white border p-3 shadow-lg text-sm rounded-lg transition-all duration-200 min-w-max">
          <div className="font-bold mb-2"> {type} Details</div>
          <ul className="grid list-disc list-inside ">
            {type === "T-Shirt" ? (
              <>
                <li className="whitespace-nowrap">100% Cotton</li>
                <li>180 g/m²</li>
              </>
            ) : type === "Hoodie" ? (
              <>
                <li className="whitespace-nowrap">
                  100% Cotton <br></br>Face
                </li>
                <li>288.2 g/m²</li>
              </>
            ) : (
              <>
                <li className="whitespace-nowrap">100% Cotton</li>
                <li>412.3 g/m²</li>
                <li>38.1 x 40.6 cm</li>
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default InfoIconClothing;
