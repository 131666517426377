import { useState } from "react";
import { CustomButton } from "../../components";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { FaTshirt } from "react-icons/fa";
import { PiHoodieFill, PiPantsFill, PiTote } from "react-icons/pi";
import InfoIconClothing from "./InfoIconClothing";
import { FaInfoCircle } from "react-icons/fa";
import { sizeOptions } from "../../config/utils";

const redCrossIcon = (
  <span className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-600" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M5.293 4.293a1 1 0 011.414 0L10 8.586l3.293-3.293a1 1 0 111.414 1.414L11.414 10l3.293 3.293a1 1 0 01-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707a1 1 0 010-1.414z" clipRule="evenodd" />
    </svg>
  </span>
);

const CustomizerOptions = ({
  selectionType,
  selectionValue,
  options,
  handleOptionSelection,
  selectedColor,
  setShowSizeGuide
}) => {
  const [showSelection, setShowSelection] = useState(false);
  const isMobile = window.innerWidth <= 600;

  const toggleSelection = () => {
    setShowSelection((prevState) => !prevState);
  };

  const handleSelectionWithToggle = (value) => {
    handleOptionSelection(value);
    toggleSelection();
  };

  const iconOnClickFunction = (isSelected, value) => {
    if (isSelected) {
      toggleSelection();
    } else {
      handleSelectionWithToggle(value);
    }
  };

  const getIcon = (isSelected, value) => {
    switch (value) {
      case "T-Shirt":
        return (
          <div style={{ position: "relative" }}>
            <FaTshirt
              key={0}
              size={28}
              className="hover:scale-110 cursor-pointer"
              color={selectedColor}
              onClick={(e) => {
                e.stopPropagation();
                iconOnClickFunction(isSelected, value);
              }}
            />
            <div
              className=""
              style={{ position: "absolute", top: -8, right: -8 }}
            >
              <InfoIconClothing type={"T-Shirt"} />
            </div>
          </div>
        );
      case "Hoodie":
        return (
          <div style={{ position: "relative" }}>
            <PiHoodieFill
              key={1}
              size={28}
              className="hover:scale-110 cursor-pointer"
              color={selectedColor}
              onClick={(e) => {
                e.stopPropagation();
                iconOnClickFunction(isSelected, value);
              }}
            />
            <div style={{ position: "absolute", top: -8, right: -8 }}>
              <InfoIconClothing type={"Hoodie"} />
            </div>
          </div>
        );
      case "Tote-bag":
        return (
          <div style={{ position: "relative" }}>
            <PiTote
              key={2}
              size={28}
              className="hover:scale-110 cursor-pointer"
              color={selectedColor}
              onClick={(e) => {
                e.stopPropagation();
                iconOnClickFunction(isSelected, value);
              }}
            />
            <div style={{ position: "absolute", top: -8, right: -8 }}>
              <InfoIconClothing type={"Tote-bag"} />
            </div>
          </div>
        );
      case "Trousers":
        return (
          <PiPantsFill
            key={2}
            size={28}
            className="hover:scale-110 cursor-pointer"
            color={selectedColor}
            onClick={(e) => {
              e.stopPropagation();
              iconOnClickFunction(isSelected, value);
            }}
          />
        );
      default: return;
    }
  };

  const renderButtons = (options, selectionType, handleClick, selectionValue) => {
    if (selectionType === "size") {
      return sizeOptions.map((option) =>
        <CustomButton
          type={"filled"}
          key={option}
          title={option}
          disabled={!options.includes(option)}
          handleClick={(e) => {
            e.stopPropagation();
            handleClick(option, selectionType);
          }}
          customStyles={`w-7 h-7 rounded-md transition-transform duration-200 shadow-md ${selectionValue === option ? "hidden" : ""} ${options.includes(option) ? 'hover:scale-110' : 'opacity-50 cursor-not-allowed relative'}`}
          bgColor={selectedColor}
          disabledIcon={options.includes(option) ? null : redCrossIcon}
        >
        </CustomButton>
      );
    }
    return options.map((option) =>
      selectionType === "type" ? (
        option === selectionValue ? (
          ""
        ) : (
          <div>{getIcon(false, option)}</div>
        )
      ) : (
        <CustomButton
          key={option.color}
          handleClick={(e) => {
            e.stopPropagation();
            handleClick(
              option.color,
              selectionType
            );
          }}
          customStyles={`w-7 h-7 rounded-md hover:scale-110 transition-transform duration-200 shadow-md ${selectionValue ===
            option.color
            ? "hidden"
            : ""
            } ${"bg-" + option.bgClass}`}
          bgColor={option.color}
        />
      )
    );
  }

  if (false)
    return (
      <div className="flex flex-row gap-3 items-center">
        <span className="text-xl mr-2">{selectionType}</span>
        {selectionType === "size"
          ? options.map((option) => {
            return (
              <button
                className={`text-center w-10 h-10 rounded-full font-medium p-2 text-xl
                        ${option === selectionValue
                    ? "bg-orange-400 text-white font-semibold"
                    : ""
                  }`}
                onClick={() => handleOptionSelection(option, selectionType)}
              >
                {option}
              </button>
            );
          })
          : selectionType === "color"
            ? options.map((option) => {
              return (
                <button
                  className={`text-center w-8 h-8 rounded-full font-medium p-2 bg-${option.bgClass
                    }
                        ${option.color === selectionValue
                      ? `ring ring-2 ring-${option.bgClass} ring-offset-2 ring-offset-inherit`
                      : ""
                    }`}
                  onClick={() =>
                    handleOptionSelection(option.color, selectionType)
                  }
                />
              );
            })
            : options.map((option) => {
              return (
                <button
                  className={`flex items-center justify-center text-center w-12 h-12 rounded-full font-medium p-2 
                        ${option === selectionValue ? "bg-orange-400" : ""}`}
                  onClick={() => handleOptionSelection(option, selectionType)}
                >
                  {getIcon(option === selectionValue, option)}
                </button>
              );
            })}
      </div>
    );
  else
    return (
      <div
        className={`flex flex-col md:flex-row gap-2 ${selectedColor === 'rgb(232, 218, 205)' ? 'bg-slate-100' : 'bg-slate-300'} p-2 rounded-md cursor-pointer`}
        onClick={(e) => {
          e.stopPropagation();
          toggleSelection(selectionType);
        }}
      >
        {selectionType === "type" ? (
          getIcon(true, selectionValue)
        ) : (
          (selectionType === "size") ? (
            <div className="relative">
              <CustomButton
                type={selectionType === "size" ? "filled" : ""}
                title={selectionType === "color" ? "" : selectionValue}
                handleClick={(e) => {
                  e.stopPropagation();
                  toggleSelection(selectionType);
                }}
                customStyles={`w-7 h-7 rounded-md hover:scale-110 transition-transform duration-200 shadow-md ring-${selectedColor === "rgb(0, 0, 0)" ? "white" : "black"
                  }`}
                bgColor={selectedColor}
              />
              <div style={{ position: "absolute", top: -8, right: -8 }} onClick={(e) => { e.stopPropagation(); setShowSizeGuide((prevState) => !prevState) }}>
                <div className="relative inline-block  ">
                  <FaInfoCircle className="h-4 z-20 w-4 md:h-4 md:w-4 text-slate-400  cursor-pointer bg-white rounded-full" />
                </div>
              </div>
            </div>
          ) : (
            <CustomButton
              type={selectionType === "size" ? "filled" : ""}
              title={selectionType === "color" ? "" : selectionValue}
              handleClick={(e) => {
                e.stopPropagation();
                toggleSelection(selectionType);
              }}
              customStyles={`w-7 h-7 rounded-md hover:scale-110 transition-transform duration-200 shadow-md ring-${selectedColor === "rgb(0, 0, 0)" ? "white" : "black"
                }`}
              bgColor={selectedColor}
            />
          )
        )
        }
        {isMobile ? (
          ""
        ) : showSelection && options.length > 1 ? (
          <FiChevronLeft
            className="self-center cursor-pointer arrow-icon text-gray-600 hover:scale-110 duration-200"
            onClick={(e) => {
              e.stopPropagation();
              toggleSelection(selectionType);
            }}
          />
        ) : options.length > 1 ? (
          <FiChevronRight
            className="self-center cursor-pointer arrow-icon text-gray-600 hover:scale-110 duration-200"
            onClick={(e) => {
              e.stopPropagation();
              toggleSelection(selectionType);
            }}
          />
        ) : (
          ""
        )}
        {showSelection &&
          renderButtons(
            options,
            selectionType,
            handleSelectionWithToggle,
            selectionValue
          )}
      </div>
    );
};

export default CustomizerOptions;
