import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { slideAnimation } from "../config/motion";

const Header = () => {
  const navigate = useNavigate();

  return (
    <motion.header
      className="flex flex-row absolute bg-white w-full justify-between items-center py-6 px-6 md:px-12 z-10"
      {...slideAnimation("down")}
    >
      <img
        src="./logo-site.png"
        alt="logo"
        className="z-10 h-8 py-2 object-contain hover:scale-110 cursor-pointer"
        onClick={() => {
          navigate("/");
        }}
      />
    </motion.header>
  );
};

export default Header;
