import { motion } from "framer-motion";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { convertColor, getBgClass, hasMoreThanOneStamp, prices } from "../../config/utils";

const GoToPayment = ({
  selectedColor,
  selectedType,
  selectedSize,
  stamp,
  images,
  index,
}) => {
  const navigate = useNavigate();

  const isMobile = window.innerWidth <= 600;

  if (isMobile)
    return (
      <div
        className={`flex flex-row justify-between items-center w-11/12 py-2 rounded-xl hover:scale-105`}
      >
        <p className={`font-bold text-xl text-black mr-4`}>€{prices[selectedType][hasMoreThanOneStamp(stamp) ? 1 : 0]}</p>{" "}
        <button
          onClick={() =>
            navigate("/checkout", {
              state: {
                selectedType,
                selectedSize,
                selectedColor,
                stamp,
                images,
                index,
              },
            })
          }
          className={`flex flex-1 items-stretch justify-center rounded-md py-1.5 px-4 border border-2 bg-${getBgClass(selectedType,
            selectedColor
          )} text-xl font-semibold ${
            convertColor(selectedColor) !== "white"
              ? "text-white border-transparent"
              : "text-black border-black"
          }`}
        >
          Pay
        </button>
      </div>
    );
  else
    return (
      <div className="absolute z-10 top-5 right-5 hover:scale-105">
        <button
          onClick={() =>
            navigate("/checkout", {
              state: {
                selectedType,
                selectedSize,
                selectedColor,
                stamp,
                images,
                index,
              },
            })
          }
          className={`flex items-center justify-between bg-black py-1.5 px-4 rounded-md hover:bg-slate-700 ${
            convertColor(selectedColor) === "white" ? "ring-2 ring-black" : ""
          }`}
          style={{
            backgroundColor:
              convertColor(selectedColor) === "white" ? "white" : selectedColor,
          }}
        >
          <p
            className={`font-bold text-lg mr-2 ${
              convertColor(selectedColor) === "white"
                ? "text-black"
                : "text-white"
            }`}
          >
            €{prices[selectedType][hasMoreThanOneStamp(stamp) ? 1 : 0]}
          </p>{" "}
          <motion.div
            whileHover={{ scale: 1.15 }} // Scale up the icon when hovered
          >
            <AiOutlineShoppingCart
              className={`h-6 w-6 ${
                convertColor(selectedColor) === "white"
                  ? "text-black"
                  : "text-white"
              } cursor-pointer`}
            />
          </motion.div>
        </button>
      </div>
    );
};

export default GoToPayment;
