import React from "react";
import { Link } from "react-router-dom";
import { convertColor } from "../../config/utils";

const CustomButton = ({
  type,
  title,
  customStyles,
  handleClick,
  bgColor,
  to,
  disabled,
}) => {
  const generateStyle = (type) => {
    if (type === "filled") {
      return {
        backgroundColor: bgColor ? bgColor : "black",
        color: bgColor && convertColor(bgColor) === "white" ? "black" : "white",
      };
    } else if (type === "outline") {
      return {
        borderWidth: "2px",
        borderColor: "black",
        color: bgColor && convertColor(bgColor) === "white" ? "black" : "white",
      };
    } else {
      return {
        backgroundColor: bgColor ? bgColor : "",
        color: bgColor && convertColor(bgColor) === "white" ? "black" : "white",
      };
    }
  };

  if (to) {
    return (
      <Link
        to={to}
        className={`rounded-md ${customStyles}`}
        style={generateStyle(type)}
      >
        {title}
      </Link>
    );
  } else {
    return (
      <button
        className={`relative rounded-md ${customStyles}`}
        style={generateStyle(type)}
        onClick={handleClick}
        disabled={disabled}
      >
        {title}
        {disabled && <span
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '100%',
            height: '2px',
            backgroundColor: 'red',
            transform: 'translate(-50%, -50%) rotate(45deg)',
          }}
        />}
      </button>
    );
  }
};

export default CustomButton;
