import { useRef, useState, useEffect } from "react";
import { generateImage } from "../config/openai";
import { convertColor, checkAndUpdateRateLimit } from "../config/utils";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase/firebase";
import { BsArrowDown } from "react-icons/bs";
import { TbBulbFilled } from 'react-icons/tb';
import StyleSuggestions from "./customizer/StyleSuggestions";

const TShirtDesigns = [
  "Hyper realistic penguin surfing on ice cream       ",
  "Low poly flamingos partying on a snowy mountaintop       ",
  "Cartoon style giraffe playing basketball against meerkats       ",
  "Minimalist octopus conducting underwater symphony       ",
  "Hyper realistic kangaroo moonwalking on Mars       ",
  "Vintage bear performing ballet on a lily pad       ",
  "Low poly hippo sunbathing on a beach       ",
  "Cartoon style rhino in a chess tournament against squirrels       ",
  "Minimalist bees having a picnic in a city park       ",
  "Vintage owls in a rock band in the forest       ",
  "Hyper realistic dog flying a kite       ",
  "Low poly cat playing the drums       ",
  "Cartoon style rabbit winning a marathon       ",
  "Minimalist lion baking a cake       ",
  "Hyper realistic shark knitting a sweater       ",
  "Vintage snail riding a bicycle       ",
  "Low poly elephant painting a mural       ",
  "Cartoon style turtle playing soccer       ",
  "Minimalist raccoon playing the piano       ",
  "Vintage squirrel doing a magic show       ",
];

const PromptInput = ({ selectedColor, setImages }) => {
  const inputRef = useRef(null);
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [i, setI] = useState(0);
  const [j, setJ] = useState(Math.floor(Math.random() * TShirtDesigns.length));
  const [isExpanded, setIsExpanded] = useState(false);
  const [promptError, setPromptError] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (text === "" && !isLoading) {
        if (i === 0) {
          setPrompt("");
        }
        if (i < TShirtDesigns[j].length) {
          setPrompt((prevPrompt) => prevPrompt + TShirtDesigns[j].charAt(i));
          setI((prevI) => prevI + 1);
        } else {
          setI(0);
          setJ((prevJ) => (prevJ + 1) % TShirtDesigns.length);
        }
      }
    }, 80);

    return () => clearTimeout(timer);
  }, [prompt, isLoading, text, i, j]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (text !== "") {
        setPrompt("");
      }
    }, 1680);

    return () => clearTimeout(timer);
  }, [prompt, text]);

  const handleTextChange = (event) => {
    setPromptError(false);
    setText(event.target.value);
  };

  async function getImage(prompt, rateLimitData) {
    setIsLoading(true);
    setPromptError(false);
    try {
      const downloaded_image = await generateImage(prompt);
      if (downloaded_image) {
        setImages((prevState) => [
          ...prevState,
          { url: downloaded_image, otherUrl: "", withBg: true, title: prompt },
        ]);
        // Update the rate limit data in local storage
        localStorage.setItem('rateLimitData', JSON.stringify(rateLimitData));
      }
    } catch (error) {
      setPromptError(true);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleButtonClick = async () => {
    const rateLimitData = checkAndUpdateRateLimit()
    if (rateLimitData) {
      if (process.env.NODE_ENV === "production" && window.location.origin === "https://tazzu.net") {
        logEvent(analytics, "select_content", {
          content_type: "DALL-E Request",
        });
      }
  
      if (text.length === 0) {
        setText(TShirtDesigns[j].trim());
        setI(0);
        setJ((prevJ) => (prevJ + 1) % TShirtDesigns.length);
        await getImage(TShirtDesigns[j].trim(), rateLimitData);
      } else {
        await getImage(text, rateLimitData);
      }
    }
  };

  const handleUseSuggestionPrompt = (style, prompt) => {
    setText(text.length === 0 ? style + prompt : style + text);
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="relative w-11/12 md:w-1/2 mb-0 md:mb-4">
      <div>
        <div>
          <div
            className="relative w-11/12 md:w-1/2 mb-0 md:mb-4 opacity-80 z-50"
            style={{
              height: isExpanded ? "185px" : "0",
              transition: "height 0.3s ease-in-out",
              overflow: "hidden",
              width: "100%",
              position: "absolute",
              bottom: "100%",
              backgroundColor: "",
              marginBottom: "10px",
            }}
          >
            {isExpanded && (
              <div className="relative  right-0">
                {" "}
                <button
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="mb-2 bg-blue-400 hover:bg-blue-500 text-white py-1 px-2 rounded focus:outline-none"
                >
                  <BsArrowDown size={16}></BsArrowDown>
                </button>
                {/* Slide-up box */}
              </div>
            )}
            <StyleSuggestions
              handleUseSuggestionPrompt={handleUseSuggestionPrompt}
            ></StyleSuggestions>
          </div>
        </div>

        {promptError && (
          <div className="absolute -top-[5.3rem] md:-top-[2.8rem] left-0 max-w-[50%] md:max-w-[70%]">
            <div className="mb-2 text-red-500 text-sm">
              Please re-write your prompt.<br></br>It can't contain any sensitive/sexual content.
            </div>
          </div>
        )}
        
        {!isExpanded && (
          <div className="absolute -top-12 right-0 max-w-[50%] md:max-w-[30%]">
            {" "}
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="flex items-center gap-2 mb-2 bg-blue-400 opacity-90 text-white font-medium py-2 px-4 rounded hover:bg-blue-500 focus:outline-none"
            >
              <span>Prompt Styles</span>
              <TbBulbFilled color="white" size={22}></TbBulbFilled>
            </button>
          </div>
        )}
      </div>
      <div className={``}>
        <textarea
          ref={inputRef}
          value={text}
          onChange={handleTextChange}
          onKeyDown={async (e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleButtonClick();
            }
          }}
          placeholder={prompt}
          className={`customizer-input py-4 pl-3 pr-14 h-auto md:h-14 w-full overflow-y-hidden resize-none z-40 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${isLoading ? "opacity-50 cursor-wait" : ""
            }`}
          disabled={isLoading}
        />
        <button
          type="submit"
          onClick={handleButtonClick}
          disabled={text.length <= 0 && prompt.length <= 0}
          className={`absolute right-2 z-40 ${convertColor(selectedColor) === "white"
            ? " enabled:ring-2 enabled:ring-black text-black"
            : "text-white"
            }  p-2 rounded-md disabled:bg-transparent disabled:text-slate-400 ${isLoading ? "opacity-50 cursor-wait" : "hover:scale-110"
            }`}
          style={{
            top: "calc(50% - 23px)",
            backgroundColor:
              text.length <= 0 && prompt.length <= 0
                ? "transparent"
                : convertColor(selectedColor) === "white"
                  ? "white"
                  : selectedColor,
          }}
        >
          {isLoading ? (
            <div
              className={`animate-spin h-5 w-5 border-t-2 ${convertColor(selectedColor) === "white"
                ? "border-black"
                : "border-white"
                }`}
            ></div>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default PromptInput;
