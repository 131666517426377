import './App.css';
import { Home, Customizer, Checkout, Tracking } from './pages';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { getApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";


function App() {
  const functions = getFunctions(getApp());
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);

  return (
    <Router>
      <main className="app transition-all ease-in">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/customizer" element={<Customizer />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/tracking" element={<Tracking />} />
          {/* Add more routes here */}
        </Routes>
      </main>
    </Router>
  );
}


export default App;
