import { useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { FaCreditCard } from "react-icons/fa"; // import the icon
import {
  printPlacement,
  prices_with_transport_fee,
  hasMoreThanOneStamp,
  convertToBlob,
} from "../../config/utils";
import ReactLoading from "react-loading";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase/firebase";
import ShippingDetails from "./ShippingDetails";
import OrderDetails from "./OrderDetails";
import PaymentModal from "./PaymentModal";
import ExpressCheckoutButton from "./ExpressCheckoutButton";

function CheckoutForm({ selectedType, selectedSize, selectedColor, stamp, images, index, redirectedStatus, paymentIntentId, paymentInfo }) {
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [paymentStatus, setPaymentStatus] = useState({
    success: false,
    message: "",
  });
  const [scrollPosition, setScrollPosition] = useState(0);
  const [visibleAreaHeight, setVisibleAreaHeight] = useState(0);
  const [formError, setFormError] = useState(false);
  const [isPaymentsElementLoaded, setIsPaymentsElementsLoaded] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const containerRef = useRef(null);
  const hasRunEffect = useRef(false); // Ref to track whether the effect has run


  useEffect(() => {
    const processPayment = async () => {
      if (stripe && !hasRunEffect.current) {
        if (redirectedStatus === 'succeeded') {
          try {
            await processOrderRequest(paymentIntentId, paymentInfo.name, paymentInfo.email, paymentInfo.address,
              paymentInfo.cardOwner, paymentInfo.selectedType, paymentInfo.selectedSize, paymentInfo.selectedColor, paymentInfo.stamp);
          } catch (error) {
            console.error("Error: ", error);
            setPaymentStatus({
              success: false,
              message: `Error: ${error.message}`,
            });
          }
          openModal();
        } else if (redirectedStatus === 'failed') {
          setPaymentStatus({ success: false, message: "We're sorry, but we couldn't process your payment.\nTry again or with a different payment method." });
          localStorage.removeItem(paymentIntentId);
          openModal();
        }

        if (paymentIntentId && localStorage.getItem(paymentIntentId))
          localStorage.removeItem(paymentIntentId);
        hasRunEffect.current = true;
      }
    };

    processPayment(); // Call the asynchronous function

  }, [stripe]);


  useEffect(() => {
    if (elements && !elements.getElement('payment')) {
      const options = {
        layout: {
          type: 'tabs',
          defaultCollapsed: false,
        }
      }
      const paymentElement = elements.create('payment', options);
      paymentElement.mount('#payment-element');
      paymentElement.on('ready', () => { handleScroll(); setIsPaymentsElementsLoaded(true) });
    }
  }, [elements]);

  useEffect(() => {
    handleScroll();
    const container = containerRef.current;
    if (container) {
      const resizeObserver = new ResizeObserver(handleScroll);
      resizeObserver.observe(container);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [containerRef, visibleAreaHeight]);

  const handleScroll = () => {
    if (!isMobile && containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const visibleHeight = Math.min(clientHeight, scrollHeight - scrollTop);
      setVisibleAreaHeight((visibleHeight / scrollHeight) * 100);

      const newScrollPosition = (scrollTop / (scrollHeight - clientHeight)) * (100 - visibleAreaHeight);
      setScrollPosition(newScrollPosition);
    }
  };

  const handleEmailChange = (event) => {
    if (event.complete) {
      setEmail(event.value.email);
    }
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  async function processOrderRequest(paymentIntentId, userName, email, address, cardOwner = "", selectedType,
    selectedSize, selectedColor, stamp) {

    console.log("DB PREPARING REQUEST");
    const order = {
      userName: userName,
      email: email,
      street: address.line2 ? address.line1 + ', ' + address.line2 : address.line1,
      country: address.country,
      city: address.city,
      postalCode: address.postal_code,
      cardOwner: cardOwner,
      selectedType: selectedType,
      selectedSize: selectedSize,
      selectedColor: selectedColor,
    };

    const formData = new FormData();
    formData.append('paymentIntentId', JSON.stringify(paymentIntentId));
    formData.append('order', JSON.stringify(order));
    if (stamp.logo)
      formData.append('stampLogo', await convertToBlob(stamp.logo));
    if (stamp.front)
      formData.append('stampFront', await convertToBlob(stamp.front));
    if (stamp.back)
      formData.append('stampBack', await convertToBlob(stamp.back));

    const response = await axios.post(
      (process.env.NODE_ENV === "production" && window.location.origin === "https://tazzu.net")
        ? "https://us-central1-aiclothing.cloudfunctions.net/app/confirm-payment"
        : "http://localhost:5001/aiclothing/us-central1/app/confirm-payment",
      formData,
      {
        headers:
        {
          "Content-Type": "multipart/form-data"
        }
      }
    );

    if (response.status === 200) {
      setOrderId(response.data.orderId);
      register_purchase_analytics();
      setPaymentStatus({
        success: true,
        message: "Payment was successful.",
      });
    } else {
      setPaymentStatus({
        success: false,
        message: `Error: ${response.error.message}.\nPlease try again`,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!elements) {
      return;
    }

    setIsProcessing(true);
    const address = await elements.getElement('address').getValue();

    elements.submit().then(async (result) => {
      if (result.error) {
        console.error(result.error);
        setFormError(true);
      } else {
        setFormError(false);

        const name = address.value.name;
        await confirmPayment(name, email, address.value.address);
      }
      setIsProcessing(false);
    })
  };

  async function confirmPayment(name, email, address, cardOwner = name) {
    try {
      const response = await axios.post(
        (process.env.NODE_ENV === "production" && window.location.origin === "https://tazzu.net")
          ? "https://us-central1-aiclothing.cloudfunctions.net/app/create-payment-intent"
          : "http://localhost:5001/aiclothing/us-central1/app/create-payment-intent",
        { email, selectedType, stamp }
      );

      if (response.data.error) {
        console.error("Server error: ", response.data.error);
        setPaymentStatus({ success: false, message: response.data.error.message });
      } else {

        // Store the necessary data in local storage
        localStorage.setItem(response.data.paymentIntentId, JSON.stringify({
          name: name,
          email: email,
          address: address,
          cardOwner: cardOwner,
          selectedColor: selectedColor,
          selectedType: selectedType,
          selectedSize: selectedSize,
          stamp: stamp,
          images: images,
          index: index
        }));

        const { error } = await stripe.confirmPayment(
          {
            elements,
            clientSecret: response.data.clientSecret,
            confirmParams: {
              return_url: `${window.location.origin}/checkout`
            },
            redirect: 'if_required'
          }
        );

        if (error) {
          console.error("Error: ", error);
          setPaymentStatus({
            success: false,
            message: "Error: " + error.message,
          });
        } else {
          await processOrderRequest(response.data.paymentIntentId, name, email, address, cardOwner, selectedType, selectedSize, selectedColor, stamp);
        }
        localStorage.removeItem(response.data.paymentIntentId);
      }
    } catch (error) {
      console.error("Error: ", error);
      setPaymentStatus({
        success: false,
        message: `Error: ${error.message}`,
      });
    }
    openModal();
  }

  const register_purchase_analytics = () => {
    if (process.env.NODE_ENV === "production" && window.location.origin === "https://tazzu.net") {
      logEvent(analytics, "purchase", {
        currency: "EUR",
        transaction_id: orderId,
        value: parseFloat(
          prices_with_transport_fee[selectedType][hasMoreThanOneStamp(stamp) ? 1 : 0] 
        ),
        items: [
          {
            item_name: selectedType,
          },
        ],
      });
    }
  };

  const isMobile = window.innerWidth <= 600;

  const scrollIndicatorContainerStyle = {
    position: "relative",
    overflow: "hidden",
  };

  const scrollIndicatorStyle = {
    position: "absolute",
    top: 0,
    right: 0,
    width: "8px",
    height: "100%",
    backgroundColor: "rgba(200, 200, 200, 0.4)",
    cursor: "pointer",
    borderRadius: "8px",
    transition: "opacity 0.3s ease",
  };

  const scrollIndicatorThumbStyle = {
    position: "absolute",
    top: `${scrollPosition}%`,
    width: "100%",
    height: `${visibleAreaHeight}%`,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
    transition: "top 0.3s ease",
  };

  const contentHeight = containerRef.current
    ? containerRef.current.scrollHeight - containerRef.current.clientHeight
    : 0;

  return (
    <div className="flex flex-col md:flex-row h-full px-4 md:px-16 items-center w-full pt-16 pb-4 md:pb-16  overflow-y-scroll md:overflow-y-hidden">

      {/* Left-column (OrderDetails) */}
      <div className="w-full px-2 md:px-0 md:w-[55%] md:h-full md:overflow-hidden">
        <OrderDetails
          selectedType={selectedType}
          prices={prices_with_transport_fee}
          hasMoreThanOneStamp={hasMoreThanOneStamp}
          stamp={stamp}
          selectedColor={selectedColor}
          isMobile={isMobile}
          selectedSize={selectedSize}
          printPlacement={printPlacement}
        />
      </div>

      {/* Right-column (Scrollable) */}
      <div className="flex-1 md:w-[45%] md:h-full my-10 md:mx-2 pb-0 md:pl-4 w-full" style={isMobile || contentHeight <= 0 ? null : scrollIndicatorContainerStyle}>
        <div
          id='payment-section'
          className="flex flex-col h-full md:h-auto md:max-h-full md:overflow-y-scroll px-2 md:pr-4"
          onScroll={handleScroll}
          ref={containerRef}
        >
          <ExpressCheckoutButton setIsProcessing={setIsProcessing} confirmPayment={confirmPayment} handleResize={handleScroll}></ExpressCheckoutButton>
          <form onSubmit={handleSubmit}>
            <ShippingDetails handleEmailChange={handleEmailChange} handleResize={handleScroll} paymentInfo={paymentInfo}></ShippingDetails>
            <div className="col-span-4 rounded pt-4 pb-8 md:p-0 mt-4 md:mt-8 md:mb-2">
              {isPaymentsElementLoaded &&
                <h2 className="text-lg font-semibold pb-4 md:p-0 md:mb-4">
                  Payment details:
                </h2>
              }
              {/*<div id="express-checkout-element" ref={elementRef}></div>*/}
              <div className="rounded ring-slate-200 ring-1  p-3">
                <div id='payment-element'></div>
              </div>
            </div>

            <div className="flex flex-col justify-center w-full">
              <div className="text-red-500 font-bold text-sm py-1 min-h-[20px]">{formError ? "Some errors to fix. Please check them." : ""}</div>
              {isPaymentsElementLoaded &&
                <button
                  type="submit"
                  className="bg-blue-600 text-white font-bold py-2 px-8 rounded-md disabled:bg-gray-300 w-full flex justify-center items-center"
                  disabled={isProcessing}
                >
                  {isProcessing ? (
                    <div className="flex justify-center items-center">
                      <ReactLoading
                        type="spin"
                        color="#ffffff"
                        height={24}
                        width={24}
                      />
                    </div>
                  ) : (
                    <div>
                      <FaCreditCard className="inline-block mr-2 mb-1" />
                      Pay Now
                    </div>
                  )}
                </button>
              }
            </div>
          </form>
        </div>
        {
          !isMobile && contentHeight > 0 &&
          <div style={scrollIndicatorStyle} className="scroll-indicator">
            <div style={scrollIndicatorThumbStyle} className="scroll-indicator-thumb" />
          </div>
        }
      </div>
      {isModalOpen && (
        <PaymentModal
          paymentStatus={paymentStatus}
          orderId={orderId}
          closeAction={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
}

export default CheckoutForm;
