import { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

const TrackingInput = ({
  trackingNumber,
  handleTextChange,
  trackOrder,
  numbers,
  setSavedTrackingNumbers,
  setTrackingNumber,
}) => {
  const [loading, setLoading] = useState(false);

  const removeFromLocalStorage = (number) => {
    if (Array.isArray(numbers) && numbers.length > 0) {
      const updatedNumbers = numbers.filter((item) => item !== number);
      localStorage.setItem("trackingNumber", JSON.stringify(updatedNumbers));
      setSavedTrackingNumbers(updatedNumbers);
    }
  };

  return (
    <div className="flex flex-1 flex-col justify-center py-6 px-6 md:px-12 gap-2">
      <h1 className="xl:text-4xl xl:leading-[3rem] leading-[2rem] mb-2 md:mb-0 text-3xl font-black text-black">
        Track your order
      </h1>
      <h2 className="md:text-xl mb-6 md:mb-0 text-lg text-slate-800 sm:pb-4 md:pb-8 max-w-xl">
        Explore your parcel's adventure by entering the Order ID from your
        confirmation email.
      </h2>
      <div className="pl-2 flex flex-row gap-2 drop-shadow-md flex-wrap max-w-xl">
        {numbers && Array.isArray(numbers)
          ? numbers
              .slice()
              .reverse()
              .map((number) => {
                return (
                  <div
                    key={number}
                    className="flex flex-row items-center gap-1 rounded-lg bg-white hover:bg-slate-50 py-1 px-2 text-sm cursor-pointer"
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setLoading(true);
                      await trackOrder(number);
                      setTrackingNumber(number);
                      setLoading(false);
                    }}
                  >
                    <span className="mr-2">{number}</span>
                    <AiFillCloseCircle
                      onClick={(e) => {
                        e.stopPropagation();
                        removeFromLocalStorage(number);
                      }}
                    ></AiFillCloseCircle>
                  </div>
                );
              })
          : ""}
      </div>
      <div className="relative max-w-xl">
        <input
          placeholder="Insert your tracking number here..."
          value={trackingNumber}
          onChange={handleTextChange}
          onKeyDown={async (e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              setLoading(true);
              await trackOrder();
              setLoading(false);
            }
          }}
          className="py-4 pl-3 pr-14 w-full md:h-14 overflow-y-hidden border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        ></input>
        <button
          type="submit"
          disabled={trackingNumber.length <= 0}
          onClick={async (e) => {
            e.preventDefault();
            setLoading(true);
            await trackOrder();
            setLoading(false);
          }}
          className={`inline-flex absolute z-10 right-0 w-14 items-center justify-center h-full text-white enabled:hover:scale-110 p-2 rounded-r-xl enabled:ring enabled:ring-1 enabled:bg-blue-500 ${
            loading
              ? "ring ring-1 bg-blue-500"
              : "disabled:bg-transparent disabled:text-slate-400"
          }`}
        >
          {loading ? (
            <div className="animate-spin h-5 w-5 border-t-2 border-white"></div>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default TrackingInput;
