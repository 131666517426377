import { useElements } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";

function ShippingDetails({
  handleEmailChange, handleResize, paymentInfo
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const elements = useElements();

  useEffect(() => {
    if (elements && !elements.getElement('address')) {
      const options = paymentInfo ? {
        mode: 'shipping',
        defaultValues: {
          name: paymentInfo.name,
          address: paymentInfo.address,
        }
      } : {
        mode: 'shipping',
      }
      const addressElement = elements.create('address', options);
      addressElement.mount('#address-element');
      addressElement.on('ready', () => { handleResize(); setIsLoaded(true) });
    }
    if (elements && !elements.getElement('linkAuthentication')) {
      const linkAuthenticationElement = paymentInfo ? elements.create('linkAuthentication', {
        defaultValues: {
          email: paymentInfo.email
        }
      }) : elements.create('linkAuthentication');
      linkAuthenticationElement.mount('#link-authentication-element');
      linkAuthenticationElement.on('change', handleEmailChange);
      linkAuthenticationElement.on('ready', () => { handleResize(); });
    }
  }, [elements, paymentInfo, handleEmailChange, handleResize]);

  return (
    <div className="col-span-4 rounded">
      {isLoaded && <h2 className="text-lg font-semibold mb-4">Shipping details:</h2>}
      <div id='link-authentication-element'></div>
      {/* <LinkAuthenticationElement onChange={handleEmailChange} onReady={() => handleResize()}></LinkAuthenticationElement> */}
      <br />
      <div id='address-element'></div>
    </div>
  );
}
export default ShippingDetails;
