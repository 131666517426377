import { motion } from "framer-motion";
import React, { useState, useRef } from "react";
import { fadeAnimation } from "../../config/motion";
import { LuUpload } from "react-icons/lu";
import { useEffect } from "react";
import { MdZoomOutMap } from "react-icons/md";
import ReactLoading from "react-loading";

const Images = ({
  images,
  handleAddImageFromDevice,
  handleImageClick,
  selectedImage,
  setShowModal,
  isLoading,
}) => {
  const fileInputRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    const containerElement = containerRef.current;
    containerElement.scrollTop = containerElement.scrollHeight;
  }, [images]);

  const handleMouseEnter = (event) => {
    event.target.style.transform = "scale(1.2)";
  };

  const handleMouseLeave = (event) => {
    event.target.style.transform = "scale(1.0)";
  };

  const handleAddButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const image = new Image();
      image.onload = function () {
        const { width, height } = this;
        console.log(width);
        console.log(height);

        if (width < 1000 && height < 1000)
          alert(
            "Oops! Your image is too small. Please upload an image with dimensions of at least 1000px on one side for optimal quality."
          );
        else {
          const url = URL.createObjectURL(file);
          handleAddImageFromDevice(url);
        }
      };
      image.src = URL.createObjectURL(file);
    }
  };

  // Use state to check window size
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const checkSize = () => {
    setWindowSize(window.innerWidth);
  };

  // Event listener to window resize
  window.addEventListener("resize", checkSize);

  // Cleanup function to remove event listener
  useEffect(() => {
    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, []);

  const isMobile = windowSize <= 768; // Consider screen width 768px or less as mobile

  return (
    <>
      <motion.div
        className="z-10 bg-slate-300 p-4 rounded-md right-5"
        {...fadeAnimation}
        ref={containerRef}
        style={{
          position: "fixed",
          top: isMobile ? "55%" : "50%",
          transform: "translateY(-50%)",
          maxHeight: isMobile ? "250px" : "500px",
          overflowY: "auto",
        }}
      >
        {images.map((image, index) => (
          <div key={index}>
            <div
              style={{
                marginBottom: isMobile ? "10px" : "20px",
                position: "relative",
              }}
              onClick={() => { handleImageClick(index); setShowModal(index); }}
            >
              <div className="md:absolute md:top-1 md:right-1 flex gap-1 justify-between mb-1 md:mb-0 z-10">
                <span className="w-5 h-5 flex items-center justify-center bg-orange-500 rounded-full text-sm text-white font-bold">
                  {index + 1}
                </span>
              </div>
              {isLoading && index === isLoading ? (
                <div className="relative w-full h-full" style={{
                  width: isMobile ? "50px" : "100px",
                  height: isMobile ? "50px" : "100px",
                }}>
                  <img
                    src={image.url}
                    alt="Modal Image"
                    className={`h-full w-full max-w-full max-h-full ${image.title ? '' : 'object-contain'} opacity-50`}
                  />
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <ReactLoading type="spin" color="black" height={24} width={24} />
                  </div>
                </div>
              ) : (
                <img
                  title={image.title}
                  src={image.url}
                  alt={`image ${index + 1}`}
                  style={{
                    cursor: "pointer",
                    width: isMobile ? "50px" : "100px",
                    height: isMobile ? "50px" : "100px",
                    transition: "transform 0.3s",
                    objectFit: "contain",
                    border:
                      selectedImage === index
                        ? "3px solid gold"
                        : isMobile
                        ? `1px solid white`
                        : "none",
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
              )}
            </div>
          </div>
        ))}
        <div
          style={{
            height: isMobile ? "50px" : "100px",
            width: isMobile ? "50px" : "100px",
            position: "relative",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={handleAddButtonClick}
          className="bg-slate-400 hover:bg-slate-500 rounded border border-slate-400 text-3xl text-slate-100"
        >
          <LuUpload />
        </div>

        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </motion.div>
    </>
  );
};

export default Images;
