import { convertColor } from "../../config/utils";

function ImageItem({ selectedColor, image, alt, setActiveImage }) {
    const handleClick = (e) => {
      e.stopPropagation();
      setActiveImage(image);
    };
  
    return (
      <div className="text-center">
        <img
          src={image}
          alt={alt}
          className={`w-[50px] h-[50px] cursor-pointer transition-transform bg-${convertColor(selectedColor)}`}
          onClick={handleClick}
        />
        <span className="text-sm">{alt}</span>
      </div>
    );
  }

export default ImageItem;