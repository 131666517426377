import { useState } from "react";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { MdLocalShipping } from "react-icons/md";
import { BsHouseFill } from "react-icons/bs";
import { getDate } from "../../config/utils";

const TrackingDetails = ({ detailsType, order }) => {
  const [showDetails, setShowDetails] = useState();

  const stateDetails = {
    new: { date: order.timestamp, status: "Preparing", rank: 1 },
    picked: { date: order.pickedDate, status: "Picked Up", rank: 2 },
    sent: { date: order.sentDate, status: "In transit", rank: 3 },
    success: { date: order.successDate, status: "Delivered", rank: 4 },
  };

  const currentStateRank = stateDetails[order.state]?.rank || (order.state === 'warning' || order.state === 'archived') ? 5 : 0;

  return (
    <div className="pb-4">
      <div
        className="flex flex-row justify-between items-center border-t-2 border-slate-200 pt-4 cursor-pointer"
        onClick={() => setShowDetails((prevState) => !prevState)}
      >
        <p className="font-semibold text-lg">
          {detailsType === "shipment" ? "Shipment Details" : "Address Details"}
        </p>
        {showDetails ? (
          <BiChevronDown
            className="rounded-full hover:bg-black hover:text-white"
            size={24}
            onClick={(e) => {
              e.stopPropagation();
              setShowDetails(false);
            }}
          ></BiChevronDown>
        ) : (
          <BiChevronRight
            className="rounded-full hover:bg-black hover:text-white"
            size={24}
            onClick={(e) => { e.stopPropagation(); setShowDetails(true) }}
          ></BiChevronRight>
        )}
      </div>
      {showDetails ? (
        <div className="mt-4">
          <div className="flex flex-col gap-4">
            {detailsType === "shipment" ? (
              Object.values(stateDetails).slice().reverse().map(
                (detail, index) =>
                  detail.date &&
                  detail.rank <= currentStateRank && (
                    <div className="flex flex-row gap-2" key={index}>
                      <div className="w-7 h-7 rounded-full bg-slate-200 flex items-center justify-center">
                        <MdLocalShipping></MdLocalShipping>
                      </div>
                      <div className="mt-[2px]">
                        <p>{detail.status === 'Preparing' ? getDate(new Date(detail.date._seconds * 1000 + detail.date._nanoseconds/1000000)) : getDate(new Date(detail.date))}</p>
                        <p className="mt-1 text-slate-600">{`${detail.status === 'Preparing' ? 'Your order was created and started to be prepared.' : 'Your order status was updated to ' + detail.status + '.'}`}</p>
                      </div>
                    </div>
                  )
              )
            ) : (
              <div className="flex flex-row gap-2">
                <div className="w-7 h-7 rounded-full bg-slate-200 flex items-center justify-center">
                  <BsHouseFill></BsHouseFill>
                </div>
                <div className="mt-[2px]">
                  <p>
                    {
                      order.postalCode +
                      " " +
                      order.city +
                      ", " +
                      ((typeof (order.country) === 'string') ? order.country : order.country.label)
                    }
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TrackingDetails;
