import {
  printPlacement,
  prices_with_transport_fee,
  hasMoreThanOneStamp,
} from "../../config/utils";

import CanvasModel from "../../canvas";
import { InfoIconWithTooltip, ImageComponent } from "../../components";

function OrderDetails({
  selectedType,
  stamp,
  selectedColor,
  isMobile,
  selectedSize,
}) {
  return (
    <div className="flex flex-col w-full h-full md:w-[90%]">
      <div className="flex flex-row justify-between md:hidden">
        <div>
          <h2 className="text-lg font-medium mb-1">TAZZU {selectedType}</h2>
          <h2 className="text-4xl font-semibold mb-1">
            {prices_with_transport_fee[selectedType][hasMoreThanOneStamp(stamp) ? 1 : 0]} €
            <InfoIconWithTooltip selectedType={selectedType}></InfoIconWithTooltip>
            <span className="block text-xs font-medium">Taxes included</span>
          </h2>
        </div>
        <ImageComponent
          selectedColor={selectedColor}
          stamp={stamp}
        ></ImageComponent>
      </div>

      <div className="hidden md:flex flex-row">
        <div className="flex flex-col flex-1">
          <h2 className="text-lg font-medium mb-1">TAZZU {selectedType}</h2>
          <div className="hidden md:block text-4xl font-semibold mb-1">
            <div className="flex flex-col">
              <div>
                <span>
                  {" "}
                  {prices_with_transport_fee[selectedType][hasMoreThanOneStamp(stamp) ? 1 : 0]} €
                </span>
                <InfoIconWithTooltip selectedType={selectedType}></InfoIconWithTooltip>
              </div>
              <span className="text-xs font-medium">Shipping fee included</span>
              <span className="text-xs font-medium">Taxes included</span>
            </div>
          </div>
        </div>
        <ImageComponent
          selectedColor={selectedColor}
          stamp={stamp}
        ></ImageComponent>
      </div>

      <div className="hidden md:contents"></div>
      {isMobile ? (
        <div className="h-[450px]">
          <CanvasModel
            cloth={selectedType}
            color={selectedColor}
            stamp={stamp}
          />
        </div>
      ) : (
        <CanvasModel cloth={selectedType} color={selectedColor} stamp={stamp} />
      )}
      {selectedType !== "Tote-bag" && (
        <div className="flex flex-row gap-2">
          <h2 className="text-lg font-medium mb-1 ">Size:</h2>
          <p className="text-xl text-blue-600">{selectedSize}</p>
        </div>
      )}
      <div className="flex flex-row gap-2">
        <h2 className="text-lg font-medium mb-1 ">Image Placement:</h2>
        <p className="text-xl text-blue-600">{printPlacement(stamp)}</p>
      </div>
    </div>
  );
}

export default OrderDetails;
