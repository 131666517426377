import { storage } from "../firebase/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const typeOptions = ["T-Shirt", "Hoodie", "Tote-bag"];

/**
export const colorOptions = [
{
  color: "rgb(255, 255, 255)",
  bgClass: "white",
  ringClass: "ring-black",
},
{
  color: "rgb(0, 0, 0)",
  bgClass: "black",
  ringClass: "ring-transparent",
},
{
  color: "rgb(59, 130, 246)",
  bgClass: "blue-500",
  ringClass: "ring-black",
},
{
  color: "rgb(34, 197, 94)",
  bgClass: "green-500",
  ringClass: "ring-black",
},
{
  color: "rgb(239, 68, 68)",
  bgClass: "red-500",
  ringClass: "ring-black",
},
{
  color: "rgb(245, 245, 220) ",
  bgClass: "brown-400",
  ringClass: "ring-black",
},
];
*/

export const colorOptions = {
  "T-Shirt": [
    {
      name: "white",
      color: "rgb(255, 255, 255)",
      bgClass: "white",
      ringClass: "ring-black",
      sizes: ["S", "M", "L", "XL", "2XL", "3XL"],
    },
    {
      name: "black",
      color: "rgb(0, 0, 0)",
      bgClass: "black",
      ringClass: "ring-transparent",
      sizes: ["S", "M", "L", "XL", "2XL", "3XL"],
    },
    {
      name: "beige",
      color: "rgb(232, 218, 205)",
      bgClass: "white",
      ringClass: "ring-black",
      sizes: ["S", "M", "L", "XL", "2XL", "3XL"],
    },
  ],
  Hoodie: [
    {
      name: "white",
      color: "rgb(255, 255, 255)",
      bgClass: "white",
      ringClass: "ring-black",
      sizes: ["M", "XL", "2XL"],
    },
    {
      name: "black",
      color: "rgb(0, 0, 0)",
      bgClass: "black",
      ringClass: "ring-transparent",
      sizes: ["S", "M", "L", "XL", "2XL"],
    },
  ],
  "Tote-bag": [
    {
      name: "beige",
      color: "rgb(232, 218, 205)",
      bgClass: "white",
      ringClass: "ring-black",
      sizes: ["One Size"],
    },
  ],
};

export function findColorIndex(clothingType, color) {
  const options = colorOptions[clothingType];
  if (options) {
    for (let i = 0; i < options.length; i++) {
      if (options[i].color === color) {
        return i;
      }
    }
  }
  return -1; // If color is not found in the clothing type array, return -1 as an indicator.
}

export const sizeOptions = ["S", "M", "L", "XL", "2XL", "3XL"];

export const prices = {
  "T-Shirt": ["24.99", "29.99"],
  Hoodie: ["44.99", "49.99"],
  "Tote-bag": ["19.99", "19.99"],
};

export const prices_with_transport_fee = {
  "T-Shirt": ["29.99", "34.99"],
  Hoodie: ["49.99", "54.99"],
  "Tote-bag": ["24.99", "24.99"],
};

export const trackingStates = [
  "Preparing",
  "Picked up",
  "In Transit",
  "Delivered",
];

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function hasMoreThanOneStamp(stamp) {
  let stampProperties = Object.keys(stamp);
  let nonEmptyProperties = stampProperties.filter(
    (property) => stamp[property] !== ""
  );
  return nonEmptyProperties.length > 1;
}

export function getDate(date) {
  let weekday = daysOfWeek[date.getDay()];
  let month = months[date.getMonth()];
  let day = date.getDate();
  let year = date.getFullYear();
  let hours = date.getHours().toString().padStart(2, "0");
  let minutes = date.getMinutes().toString().padStart(2, "0");

  return `${weekday}, ${month} ${day}, ${year} @ ${hours}:${minutes}`;
}

export const formatDateRange = (startDate, endDate) => {
  let startMonth = months[startDate.getMonth()];
  let startDay = startDate.getDate();
  let endMonth = months[endDate.getMonth()];
  let endDay = endDate.getDate();
  let endYear = endDate.getFullYear();
  return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${endYear}`;
};

export const getBgClass = (product, color) => {
  const foundElement = colorOptions[product].find(
    (option) => option.color === color
  );
  if (foundElement) {
    return foundElement.bgClass;
  } else {
    return null; // or any default value you prefer when no matching element is found
  }
};

export const getColorName = (product, color) => {
  const foundElement = colorOptions[product].find(
    (option) => option.color === color
  );
  if (foundElement) {
    return foundElement.name;
  } else {
    return null; // or any default value you prefer when no matching element is found
  }
};

export function convertColor(rgb) {
  // Remove the 'rgb(' and ')' from the input string
  rgb = rgb.slice(4, -1);

  // Split the string into an array of color values
  var colorValues = rgb.split(",");

  // Parse the color values as integers
  var red = parseInt(colorValues[0]);
  var green = parseInt(colorValues[1]);
  var blue = parseInt(colorValues[2]);

  // Define an object with color name mappings
  var colorNames = {
    black: [0, 0, 0],
    white: [255, 255, 255],
    red: [255, 0, 0],
    green: [0, 255, 0],
    blue: [0, 0, 255],
    // Add more color mappings here...
  };

  // Find the closest color match
  var closestColor = "";
  var closestDistance = Infinity;

  for (var color in colorNames) {
    var colorValue = colorNames[color];
    var distance = Math.sqrt(
      Math.pow(red - colorValue[0], 2) +
      Math.pow(green - colorValue[1], 2) +
      Math.pow(blue - colorValue[2], 2)
    );

    if (distance < closestDistance) {
      closestDistance = distance;
      closestColor = color;
    }
  }

  return closestColor;
}

export const convertToBlob = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error converting image to base64:", error);
    throw error;
  }
};



export function printPlacement(placement) {
  const vars = [
    { name: "front", value: placement.front },
    { name: "back", value: placement.back },
    { name: "logo", value: placement.logo },
  ];

  const selectedVars = vars.filter((variable) => variable.value);

  if (selectedVars.length === 0) {
    console.log("No variables selected.");
    return;
  }

  const names = selectedVars.map((variable) => variable.name);

  if (names.length === 1) {
    return names[0];
  } else {
    const lastVar = names.pop();
    const joinedVars = names.join(", ");
    return `${joinedVars} and ${lastVar}`;
  }
}

// Function to check and update rate limits
export const checkAndUpdateRateLimit = () => {
  const requestLimit = 15;
  const timeWindow = 60 * 60 * 1000; // 2 hours in milliseconds
  const storageKey = 'rateLimitData';

  // Get the stored rate limit data from local storage
  const rateLimitData = JSON.parse(localStorage.getItem(storageKey)) || { count: 0, timestamp: Date.now() };

  // Check if the time window has passed
  if (Date.now() - rateLimitData.timestamp > timeWindow) {
    // Reset the count if the time window has passed
    rateLimitData.count = 0;
    rateLimitData.timestamp = Date.now();
  }

  // Check if the user has exceeded the rate limit
  if (rateLimitData.count >= requestLimit) {
    const remainingTime = Math.ceil((timeWindow - (Date.now() - rateLimitData.timestamp)) / (60 * 1000)); // Remaining minutes
    alert(`Rate limit of 15 requests/hour exceeded. Please try again in ${remainingTime} minutes.`);
    return null; // Do not make the API request
  }

  // Increment the request count
  rateLimitData.count += 1;

  return rateLimitData; // Proceed with the API request
};

