import React from "react";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';

function PaymentModal({ paymentStatus, orderId, closeAction }) {
  const navigate = useNavigate();

  const closeModal = () => {
    navigate("/");
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black opacity-50 z-40"></div>
      <div className="bg-white rounded-lg p-6 z-50 max-w-[80%]">
        {paymentStatus.success ? (
          <div className="text-center">
            <AiOutlineCheckCircle className="text-6xl text-green-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold mb-4">Payment Successful</h2>
            <p>{paymentStatus.message}</p>
            {/* Display the Order ID */}
            <button
              className="bg-slate-800 hover:bg-slate-900 text-white font-semibold mt-2 py-2 px-4 mx-2 rounded-md"
              onClick={() => {
                navigate(
                  (process.env.NODE_ENV === "production" && window.location.origin === "https://tazzu.net")
                    ? `/tracking?order_id=${orderId ? orderId : ""}`
                    : `/tracking?order_id=${orderId ? orderId : ""}`
                ); // navigate to tracking page
              }}
            >
              Track your order
            </button>
            <button
              className="bg-blue-600 text-white font-semibold mt-6 py-2 px-4 rounded-md"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        ) : (
          paymentStatus.message ?
            <div className="text-center">
              <AiOutlineCloseCircle className="text-6xl text-red-500 mx-auto mb-4" />
              <h2 className="text-2xl font-bold mb-4">Payment Failed</h2>
              <p>{paymentStatus.message}</p>
              <button
                className="bg-blue-600 text-white font-semibold mt-6 py-2 px-4 rounded-md"
                onClick={closeAction}
              >
                Close
              </button>
            </div> :
            <div className="flex items-center justify-center">
              <ReactLoading type="spin" color="black" height={64} width={64} />
            </div>
        )}
      </div>
    </div>
  );
}

export default PaymentModal;
