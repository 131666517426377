import { AiFillCloseCircle } from "react-icons/ai";

const SizeGuideTable = ({ data }) => {
    const thStyle = {
        borderColor: "#000000",
        borderStyle: "solid",
        borderWidth: 0,
        fontFamily: "Arial, sans-serif",
        fontSize: 14,
        fontWeight: "normal",
        overflow: "hidden",
        paddingTop: "10px",
        paddingBottom: "10px",
        textAlign: "center",
        verticalAlign: "top",
        wordBreak: "normal",
    };

    const tdStyle = {
        borderColor: "inherit",
        borderStyle: "solid",
        borderWidth: 0,
        fontFamily: "Arial, sans-serif",
        fontSize: 14,
        overflow: "hidden",
        paddingTop: "10px",
        paddingBottom: "10px",
        textAlign: "center",
        verticalAlign: "top",
        wordBreak: "normal"
    };

    return (
        <table className="min-w-full" style={{tableLayout: 'auto'}}>
            <thead>
                <tr>
                    <th />
                    <th style={thStyle}>Length</th>
                    <th style={thStyle}>Width</th>
                    {data[0].sleeve && <th style={thStyle}>Sleeve</th>}
                </tr>
            </thead>
            <tbody>
                {data.map(({ size, length, width, sleeve }) => (
                    <tr key={size}>
                        <td style={{ ...tdStyle, fontWeight: 'bold', textAlign: 'center' }}>{size}</td>
                        <td style={tdStyle}>{length}</td>
                        <td style={tdStyle}>{width}</td>
                        {sleeve && <td style={tdStyle}>{sleeve}</td>}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

const SizeGuideModal = ({ selectedType, setShowSizeGuide }) => {
    const sizeGuideData = {
        "T-Shirt": [
            { size: "S", length: 71, width: "86.4 - 94", sleeve: "39.7" },
            { size: "M", length: 73.7, width: "96.5 - 104", sleeve: "43.2" },
            { size: "L", length: 76.2, width: "106.7 - 114.3", sleeve: "47" },
            { size: "XL", length: 78.7, width: "116.8 - 124.5", sleeve: "50.8" },
            { size: "2XL", length: 81.3, width: "127 - 134.6", sleeve: "54.6" },
            { size: "3XL", length: 83.8, width: "137.2 - 144.8", sleeve: "58" },
        ],
        "Hoodie": [
            { size: "S", length: 27, width: 20 },
            { size: "M", length: 28, width: 21 },
            { size: "L", length: 29, width: 23 },
            { size: "XL", length: 30, width: 25 },
            { size: "2XL", length: 31, width: "26 1/2" },
            { size: "3XL", length: 32, width: 28 },
        ],
    };

    const data = sizeGuideData[selectedType];

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div
                className="fixed inset-0 bg-black opacity-50"
                onClick={() => setShowSizeGuide(false)}
            />
            <div className="relative bg-white border p-3 shadow-lg text-sm rounded-lg transition-all duration-200 min-w-[90%] md:min-w-[400px] max-w-[90%] md:max-w-full md:max-h-[65vh]">
                <div className="flex flex-row justify-between items-center mb-4">
                    <div className="font-bold">Size Guide (cm)</div>
                    <AiFillCloseCircle
                        className="justify-end hover:cursor-pointer self-end"
                        size={24}
                        onClick={() => setShowSizeGuide(false)}
                    />
                </div>
                <div className="flex items-center justify-center">
                    <SizeGuideTable data={data} />
                </div>
            </div>
        </div>
    );
};

export default SizeGuideModal;
