import axios from "axios";

export async function generateImage(prompt) {
  console.log("Preparing image request with prompt: " + prompt);

  const downloadImage = async (prompt) => {
    const response = await axios.post(
      process.env.NODE_ENV === "production" &&
        window.location.origin === "https://tazzu.net"
        ? `https://us-central1-aiclothing.cloudfunctions.net/app/download-image`
        : "http://localhost:8000/download-image",
      {
        prompt: prompt,
      },
      {
        responseType: "blob",
      }
    );

    if (response.status === 200) {
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = URL.createObjectURL(blob);
      return url;
    } else {
      console.error(
        "Failed to download the image:",
        response.status,
        response.statusText
      );
      return null;
    }
  };

  const downloaded_image = await downloadImage(prompt);
  return downloaded_image;
}
