import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import CheckoutForm from "../components/stripe/CheckoutForm";
import { GoBackButtonToCanvas } from "../components";
import {
  prices_with_transport_fee,
  hasMoreThanOneStamp,
} from "../config/utils";
import { analytics } from "../firebase/firebase";
import { logEvent } from "firebase/analytics";

function Checkout() {
  const { state } = useLocation();
  const searchParams = new URLSearchParams(window.location.search);

  const [stripePromise, setStripePromise] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!state && (searchParams.size === 0 || !searchParams.get("payment_intent_client_secret") || !searchParams.get("payment_intent") || !localStorage.getItem(searchParams.get("payment_intent"))))
      navigate('/');
    else {
      setStripePromise(
        loadStripe((process.env.NODE_ENV === 'production' && window.location.origin === "https://tazzu.net")
          ? process.env.REACT_APP_STRIPE_KEY_PUBLIC
          : process.env.REACT_APP_TEST_STRIPE_KEY_PUBLIC));
    }

    if (state && process.env.NODE_ENV === 'production' && window.location.origin === "https://tazzu.net") {
      /*
      logEvent(analytics, 'screen_view', {
        firebase_screen: 'Customizer',
      });
      */
      logEvent(analytics, 'begin_checkout', {
        currency: 'EUR',
        value: prices_with_transport_fee[state.selectedType][hasMoreThanOneStamp(state.stamp) ? 1 : 0],
        items: [
          {
            item_name: state.selectedType
          }
        ]
      })
    }
  }, []);

  if (state) {
    return (
      <>
        {/**<GoBackButton selectedColor={selectedColor}></GoBackButton>*/}
        <GoBackButtonToCanvas
          selectedType={state.selectedType}
          selectedSize={state.electedSize}
          selectedColor={state.selectedColor}
          stamp={state.stamp}
          images={state.images}
          index={state.index}
        ></GoBackButtonToCanvas>
        {stripePromise && <Elements stripe={stripePromise} options={{
          mode: 'payment',
          amount: parseFloat((prices_with_transport_fee[state.selectedType][hasMoreThanOneStamp(state.stamp) ? 1 : 0].replace('.', ''))),
          currency: 'eur',
          loader: 'always'
        }}>
          <CheckoutForm
            selectedType={state.selectedType}
            selectedSize={state.selectedSize}
            selectedColor={state.selectedColor}
            stamp={state.stamp}
            images={state.images}
            index={state.index}
          ></CheckoutForm>
        </Elements>}
      </>);
  } else {
    const clientSecret = searchParams.get("payment_intent_client_secret");
    const paymentIntentId = searchParams.get("payment_intent");
    // verify if is the same paymentIntent as saved in the local storage
    const redirectStatus = searchParams.get("redirect_status");
    const paymentInfo = localStorage.getItem(paymentIntentId) ? JSON.parse(localStorage.getItem(paymentIntentId)) : null;

    if (clientSecret && paymentIntentId && redirectStatus && paymentInfo) {
      return (
        <>
          {/**<GoBackButton selectedColor={selectedColor}></GoBackButton>*/}
          <GoBackButtonToCanvas
            selectedType={paymentInfo.selectedType}
            selectedSize={paymentInfo.electedSize}
            selectedColor={paymentInfo.selectedColor}
            stamp={paymentInfo.stamp}
            images={paymentInfo.images}
            index={paymentInfo.index}
          ></GoBackButtonToCanvas>
          {stripePromise && <Elements stripe={stripePromise} options={{
            mode: 'payment',
            amount: parseFloat((prices_with_transport_fee[paymentInfo.selectedType][hasMoreThanOneStamp(paymentInfo.stamp) ? 1 : 0].replace('.', ''))),
            currency: 'eur',
            loader: 'always'
          }}>
            <CheckoutForm
              selectedType={paymentInfo.selectedType}
              selectedSize={paymentInfo.selectedSize}
              selectedColor={paymentInfo.selectedColor}
              stamp={paymentInfo.stamp}
              images={paymentInfo.images}
              index={paymentInfo.index}
              redirectedStatus={redirectStatus}
              paymentIntentId={paymentIntentId}
              paymentInfo={paymentInfo}
            ></CheckoutForm>
          </Elements>}
        </>
      );
    }
  }
}

export default Checkout;
