import React, { useState } from "react";
import { convertColor, formatDateRange, getColorName, trackingStates } from "../../config/utils";
import ShipmentProgress from "./ShipmentProgress";
import TrackingDetails from "./TrackingDetails";
import { ImageItem } from "../../components";

const TrackingInfo = ({ trackingNumber, trackingStateIndex, order }) => {
  const [activeImage, setActiveImage] = useState(order.stamp.logo ? order.stamp.logo :
    order.stamp.front ? order.stamp.front :
      order.stamp.back ? order.stamp.back : '');

  /*
  const [imageURL, setImageURL] = useState(null);
  
  useEffect(() => {
    const downloadImage = async () => {
      try {
        const response = await axios.get(order.selectedImage, {
          responseType: "blob",
        });

        if (response.status === 200) {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const url = URL.createObjectURL(blob);
          setImageURL(url);
        } else {
          console.error(
            "Failed to download the image:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error while downloading the image:", error);
      }
    };

    downloadImage();

    // Cleanup function to revoke the URL when the component unmounts
    return () => {
      if (imageURL) {
        URL.revokeObjectURL(imageURL);
      }
    };
  }, [order.selectedImage]);
  */

  return (
    <div className="flex flex-1 flex-col md:flex-row items-center bg-slate-300 p-6 md:p-12 gap-10">
      {activeImage && <div className="flex flex-col justify-center w-4/5 md:w-1/4 hidden md:block">
        <img className={`object-contain bg-${convertColor(order.selectedColor)}`} src={activeImage} alt="Order Image" />
        <div className="flex flex-row justify-center gap-5 mt-4">
          {order.stamp.logo && (
            <ImageItem
              selectedColor={order.selectedColor}
              image={order.stamp.logo}
              alt="Logo"
              setActiveImage={setActiveImage}
            />
          )}
          {order.stamp.front && (
            <ImageItem
              selectedColor={order.selectedColor}
              image={order.stamp.front}
              alt="Front"
              setActiveImage={setActiveImage}
            />
          )}
          {order.stamp.back && (
            <ImageItem
              selectedColor={order.selectedColor}
              image={order.stamp.back}
              alt="Back"
              setActiveImage={setActiveImage}
            />
          )}
        </div>
      </div>}
      <div className="flex flex-1 flex-col justify-center gap-2">
        <h2 className="font-bold text-slate-600 text-lg">Track order</h2>
        <div className="flex flex-col bg-white rounded-xl drop-shadow-md p-4">
          <span>Tracking ID: <span className="text-blue-600">{trackingNumber}</span></span>
          <p className="mt-4">Product: <span className="font-semibold">{order.selectedType}</span>
            {' ('}color: <span className="font-semibold">{getColorName(order.selectedType, order.selectedColor)}</span>
            , size: <span className="font-semibold">{order.selectedSize}</span>{')'} </p>
          <p className="block md:hidden">Stamping:</p>
          {activeImage && <div className="flex flex-col self-center justify-center w-4/5 mt-4 md:w-1/4 block md:hidden">
            <img className={`object-contain bg-${convertColor(order.selectedColor)}`} src={activeImage} alt="Order Image" />
            <div className="flex flex-row justify-center gap-5 mt-4">
              {order.stamp.logo && (
                <ImageItem
                  selectedColor={order.selectedColor}
                  image={order.stamp.logo}
                  alt="Logo"
                  setActiveImage={setActiveImage}
                />
              )}
              {order.stamp.front && (
                <ImageItem
                  selectedColor={order.selectedColor}
                  image={order.stamp.front}
                  alt="Front"
                  setActiveImage={setActiveImage}
                />
              )}
              {order.stamp.back && (
                <ImageItem
                  selectedColor={order.selectedColor}
                  image={order.stamp.back}
                  alt="Back"
                  setActiveImage={setActiveImage}
                />
              )}
            </div>
          </div>}
          <p className="text-2xl font-bold mt-6">
            {trackingStates[trackingStateIndex]}
          </p>
          {order.estimatedStartDate && order.estimatedEndDate &&
            <p className="text-slate-600">
              Estimated delivery date:{" "}
              <span className="text-black">{formatDateRange(new Date(order.estimatedStartDate), new Date(order.estimatedEndDate))}</span>
            </p>
          }
          <ShipmentProgress
            trackingStateIndex={trackingStateIndex}
          ></ShipmentProgress>
          <TrackingDetails
            detailsType="shipment"
            order={order}
          ></TrackingDetails>
          <TrackingDetails
            detailsType="address"
            order={order}
          ></TrackingDetails>
        </div>
      </div>
    </div>
  );
};

export default TrackingInfo;
