import React from "react";

const StyleSuggestions = ({ handleUseSuggestionPrompt }) => {
    return (
        <div
            style={{
                display: "flex",
                overflowX: "auto",
                height: "100%",
                whiteSpace: "nowrap",
            }}
        >
            <button
                onClick={() =>
                    handleUseSuggestionPrompt("Flat style, ", " Koala in Oasis")
                }
                style={{
                    width: "150px",
                    height: "150px",
                    flexShrink: 0,
                    position: "relative",
                }}
            >
                <img
                    src="/flatstyle.png"
                    alt="Logo "
                    style={{ width: "150px", height: "150px" }}
                />
                <div
                    className="pt-2 rounded "
                    style={{
                        position: "absolute",
                        bottom: "0", // place the overlay at the bottom
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "95%",
                        padding: "10px 0", // some padding to give space from the bottom and top
                        backgroundColor: "rgba(0, 0, 0, 0.3)", // semi-transparent black
                        color: "white",
                        textAlign: "center", // horizontally center the text
                    }}
                >
                    <span className=""> Flat </span>
                </div>
            </button>
            <button
                onClick={() =>
                    handleUseSuggestionPrompt(
                        "Photorealism style, ",
                        " Soda in the desert"
                    )
                }
                style={{
                    width: "150px",
                    height: "150px",
                    flexShrink: 0,
                    position: "relative",
                }}
            >
                <img
                    src="/photorealism.png"
                    alt="Photorealism style, Soda in the desert "
                    style={{ width: "150px", height: "150px" }}
                />
                <div
                    className="pt-2 rounded "
                    style={{
                        position: "absolute",
                        bottom: "0", // place the overlay at the bottom
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "95%",
                        padding: "10px 0", // some padding to give space from the bottom and top
                        backgroundColor: "rgba(0, 0, 0, 0.3)", // semi-transparent black
                        color: "white",
                        textAlign: "center", // horizontally center the text
                    }}
                >
                    <span className=""> Photorealism </span>
                </div>
            </button>

            <button
                onClick={() => handleUseSuggestionPrompt("Pop art, ", " Flowers")}
                style={{
                    width: "150px",
                    height: "150px",
                    flexShrink: 0,
                    position: "relative",
                }}
            >
                <img
                    src="/popart.jpeg"
                    alt="Logo "
                    style={{ width: "150px", height: "150px" }}
                />
                <div
                    className="pt-2 rounded "
                    style={{
                        position: "absolute",
                        bottom: "0", // place the overlay at the bottom
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "95%",
                        padding: "10px 0", // some padding to give space from the bottom and top
                        backgroundColor: "rgba(0, 0, 0, 0.3)", // semi-transparent black
                        color: "white",
                        textAlign: "center", // horizontally center the text
                    }}
                >
                    <span className=""> Pop Art </span>
                </div>
            </button>
            <button
                onClick={() =>
                    handleUseSuggestionPrompt("Pixel Art Style, ", " Ninja in Casino")
                }
                style={{
                    width: "150px",
                    height: "150px",
                    flexShrink: 0,
                    position: "relative",
                }}
            >
                <img
                    src="/pixelart.jpeg"
                    alt="Logo "
                    style={{ width: "150px", height: "150px" }}
                />
                <div
                    className="pt-2 rounded "
                    style={{
                        position: "absolute",
                        bottom: "0", // place the overlay at the bottom
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "95%",
                        padding: "10px 0", // some padding to give space from the bottom and top
                        backgroundColor: "rgba(0, 0, 0, 0.3)", // semi-transparent black
                        color: "white",
                        textAlign: "center", // horizontally center the text
                    }}
                >
                    <span className=""> Pixel Art </span>
                </div>
            </button>

            <button
                onClick={() =>
                    handleUseSuggestionPrompt("Low Poly style, ", " Panda Under a Tree")
                }
                style={{
                    width: "150px",
                    height: "150px",
                    flexShrink: 0,
                    position: "relative",
                }}
            >
                <img
                    src="/lowpoly.jpeg"
                    alt="Logo "
                    style={{ width: "150px", height: "150px" }}
                />
                <div
                    className="pt-2 rounded "
                    style={{
                        position: "absolute",
                        bottom: "0", // place the overlay at the bottom
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "95%",
                        padding: "10px 0", // some padding to give space from the bottom and top
                        backgroundColor: "rgba(0, 0, 0, 0.3)", // semi-transparent black
                        color: "white",
                        textAlign: "center", // horizontally center the text
                    }}
                >
                    <span className=""> Low Poly </span>
                </div>
            </button>
            <button
                onClick={() =>
                    handleUseSuggestionPrompt(
                        "Minimalism style, ",
                        " Pinnaple on the water"
                    )
                }
                style={{
                    width: "150px",
                    height: "150px",
                    flexShrink: 0,
                    position: "relative",
                }}
            >
                <img
                    src="/minimalism.jpeg"
                    alt="Logo "
                    style={{ width: "150px", height: "150px" }}
                />
                <div
                    className="pt-2 rounded "
                    style={{
                        position: "absolute",
                        bottom: "0", // place the overlay at the bottom
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "95%",
                        padding: "10px 0", // some padding to give space from the bottom and top
                        backgroundColor: "rgba(0, 0, 0, 0.3)", // semi-transparent black
                        color: "white",
                        textAlign: "center", // horizontally center the text
                    }}
                >
                    <span className=""> Minimalism </span>
                </div>
            </button>
            <button
                onClick={() =>
                    handleUseSuggestionPrompt("Pointillism, ", " Man drinking red wine ")
                }
                style={{
                    width: "150px",
                    height: "150px",
                    flexShrink: 0,
                    position: "relative",
                }}
            >
                <img
                    src="/pointillism.jpeg"
                    alt="Logo "
                    style={{ width: "150px", height: "150px" }}
                />
                <div
                    className="pt-2 rounded "
                    style={{
                        position: "absolute",
                        bottom: "0", // place the overlay at the bottom
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "95%",
                        padding: "10px 0", // some padding to give space from the bottom and top
                        backgroundColor: "rgba(0, 0, 0, 0.3)", // semi-transparent black
                        color: "white",
                        textAlign: "center", // horizontally center the text
                    }}
                >
                    <span className=""> Pointillism </span>
                </div>
            </button>

            <button
                onClick={() =>
                    handleUseSuggestionPrompt(
                        "Cubism style, ",
                        " girl riding a bike in sunset"
                    )
                }
                style={{
                    width: "150px",
                    height: "150px",
                    flexShrink: 0,
                    position: "relative",
                }}
            >
                <img
                    src="/cubism.jpeg"
                    alt="Logo "
                    style={{ width: "150px", height: "150px" }}
                />
                <div
                    className="pt-2 rounded "
                    style={{
                        position: "absolute",
                        bottom: "0", // place the overlay at the bottom
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "95%",
                        padding: "10px 0", // some padding to give space from the bottom and top
                        backgroundColor: "rgba(0, 0, 0, 0.3)", // semi-transparent black
                        color: "white",
                        textAlign: "center", // horizontally center the text
                    }}
                >
                    <span className=""> Cubism </span>
                </div>
            </button>
        </div>
    );
};

export default StyleSuggestions;