import { motion } from "framer-motion";
import { fadeAnimation } from "../../config/motion";
import { useNavigate } from "react-router-dom";
import { BsFillArrowLeftCircleFill, BsArrowLeftCircle } from "react-icons/bs";
import { convertColor } from "../../config/utils";

export default function GoBackButtonToCanvas({
  selectedType,
  selectedSize,
  selectedColor,
  imagePlacement,
  stamp,
  images,
  index,
}) {
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 600;

  return (
    <motion.div className={`absolute z-10 top-5 ${isMobile ? 'left-5' : 'hover:scale-105 left-16'}`} {...fadeAnimation}>
      {convertColor(selectedColor) !== "white" ? (
        <BsFillArrowLeftCircleFill
          className="cursor-pointer"
          size={34}
          onClick={() =>
            navigate("/customizer", {
              state: {
                selectedTypeState: selectedType,
                selectedSizeState: selectedSize,
                selectedColorState: selectedColor,
                imagePlacementState: imagePlacement,
                stampState: stamp,
                imagesState: images,
                indexState: index,
              },
            })
          }
          color={selectedColor}
        ></BsFillArrowLeftCircleFill>
      ) : (
        <BsArrowLeftCircle
          className="cursor-pointer"
          size={34}
          onClick={() =>
            navigate("/customizer", {
              state: {
                selectedTypeState: selectedType,
                selectedSizeState: selectedSize,
                selectedColorState: selectedColor,
                imagePlacementState: imagePlacement,
                stampState: stamp,
                imagesState: images,
                indexState: index,
              },
            })
          }
          color="black"
        ></BsArrowLeftCircle>
      )}
    </motion.div>
  );
}
