import React, { useEffect, useState } from "react";
import { easing } from "maath";
import { useFrame } from "@react-three/fiber";
import { Decal, useGLTF } from "@react-three/drei";
import { TextureLoader } from "three";

const Shirt = ({ color, stamp }) => {
  const { nodes, materials } = useGLTF("/shirt_baked.glb");
  const [logoTexture, setLogoTexture] = useState(null);
  const [frontTexture, setFrontTexture] = useState(null);
  const [backTexture, setBackTexture] = useState(null);

  useEffect(() => {
    const loadTexture = (path, textureStateSetter) => {
      if (path !== "") {
        const loader = new TextureLoader();
        loader.load(path, (texture) => {
          texture.anisotropy = 16;
          const aspectRatio = texture.image.width / texture.image.height;

          textureStateSetter({
            texture: texture,
            aspectRatio: aspectRatio,
            scale:
              aspectRatio > 1 ? [1, 1 / aspectRatio, 1] : [aspectRatio, 1, 1],
          });
        });
      } else {
        textureStateSetter(null);
      }
    };

    loadTexture(stamp.logo, setLogoTexture);
    loadTexture(stamp.front, setFrontTexture);
    loadTexture(stamp.back, setBackTexture);
  }, [stamp]);

  useFrame((state, delta) => {
    easing.dampC(materials.lambert1.color, color, 0.25, delta);
  });

  return (
    <group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.T_Shirt_male.geometry}
        material={materials.lambert1}
        material-roughness={1}
        dispose={null}
      >
        {frontTexture && (
          <Decal
            position={[0, -0.01, 0.15]} //front
            rotation={[0, 0, 0]}
            scale={[
              0.15 * frontTexture.scale[0],
              0.15 * frontTexture.scale[1],
              0.15,
            ]}
            map={frontTexture.texture}
            depthTest={true}
            depthWrite={true}
          />
        )}
        {backTexture && (
          <Decal
            position={[0, 0.04, -0.15]} //back
            rotation={[0, Math.PI, 0]} // 180 degrees rotation around Y-axis
            scale={[
              0.22 * backTexture.scale[0],
              0.22 * backTexture.scale[1],
              0.22,
            ]}
            map={backTexture.texture}
            depthTest={true}
            depthWrite={true}
          />
        )}
        {logoTexture && (
          <Decal
            position={[0.08, 0.11, 0.1]} //logo
            rotation={[0, 0, 0]}
            scale={[
              0.07 * logoTexture.scale[0],
              0.07 * logoTexture.scale[1],
              0.07,
            ]}
            map={logoTexture.texture}
            depthTest={true}
            depthWrite={true}
          />
        )}
      </mesh>
    </group>
  );
};

useGLTF.preload("/shirt_baked.glb");

export default Shirt;
