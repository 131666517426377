import React, { useState, useEffect } from "react";
import ImageItem from "./ImageItem";

export default function ImageComponent({ selectedColor, stamp }) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(stamp.logo ? stamp.logo :
    stamp.front ? stamp.front :
      stamp.back ? stamp.back : '');

  const handleModalOpen = (image) => {
    setActiveImage(image);
    setIsOpen(true);
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && event.target.className === "backdrop") {
        handleModalClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <button
        className={`z-10 h-fit rounded-md px-3 py-2 text-sm font-semibold hover:bg-black hover:text-white border border-2 border-black ${activeImage === '' ? 'hidden' : ''}`}
        onClick={() => handleModalOpen(stamp.logo)}
      >
        See image
      </button>

      {isOpen && (
        <div
          className="backdrop fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10"
          onClick={handleModalClose}
        >
          <div className="modal-content bg-white p-4 rounded">
            <button onClick={handleModalClose}>Close</button>
            <img
              className={`w-[250px] h-[250px] ${activeImage.includes('white') ? 'bg-black' : ''} `}
              src={activeImage}
              alt={`Expanded ${activeImage}`}
            />
            <div className="flex flex-row justify-center gap-5 mt-4">
              {stamp.logo && (
                <ImageItem
                  selectedColor={selectedColor}
                  image={stamp.logo}
                  alt="Logo"
                  setActiveImage={setActiveImage}
                />
              )}
              {stamp.front && (
                <ImageItem
                  selectedColor={selectedColor}
                  image={stamp.front}
                  alt="Front"
                  setActiveImage={setActiveImage}
                />
              )}
              {stamp.back && (
                <ImageItem
                  selectedColor={selectedColor}
                  image={stamp.back}
                  alt="Back"
                  setActiveImage={setActiveImage}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
