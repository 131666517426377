import { motion } from "framer-motion";
import { CustomButton } from "../../components";
import { fadeAnimation } from "../../config/motion";
import { useNavigate } from "react-router-dom";
import { BsFillArrowLeftCircleFill, BsArrowLeftCircle } from "react-icons/bs";
import { convertColor } from "../../config/utils";

const GoBackButton = ({ selectedColor }) => {
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 600;

  const handleGoBack = () => {
    navigate("/");
  };

  if (isMobile)
    return (
      <motion.div className="absolute z-10 top-5 left-5" {...fadeAnimation}>
        {convertColor(selectedColor) !== "white" ? (
          <BsFillArrowLeftCircleFill
            size={34}
            onClick={handleGoBack}
            color={selectedColor}
          ></BsFillArrowLeftCircleFill>
        ) : (
          <BsArrowLeftCircle
            size={34}
            onClick={handleGoBack}
            color="black"
          ></BsArrowLeftCircle>
        )}
        <button
          className={`flex items-center justify-center text-center w-12 h-12 rounded-full font-medium p-2 
                        bg-${selectedColor}`}
          onClick={handleGoBack}
        ></button>
      </motion.div>
    );
  else
    return (
      <motion.div className="absolute z-10 top-5 left-5" {...fadeAnimation}>
        <CustomButton
          type={convertColor(selectedColor) === "white"
              ? "outline"
              : "filled"
          }
          className=" "
          bgColor={selectedColor}
          title="Go Back"
          handleClick={handleGoBack}
          customStyles="w-fit px-4 py-2.5 font-bold text-sm hover:scale-105"
        />
      </motion.div>
    );
};

export default GoBackButton;
