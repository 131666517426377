import { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  BsFillClipboard2Fill,
  BsFillClipboard2CheckFill,
} from "react-icons/bs";
import { FaMagic } from "react-icons/fa";
import Toggle from "./Toggle";
import { convertColor, convertToBlob } from "../config/utils";
import axios from "axios";
import ReactLoading from "react-loading";

const Modal = ({
  image,
  index,
  selectedColor,
  selectedType,
  stamp,
  images,
  selectedImageIndex,
  toggleStamp,
  closeModal,
  updateImage,
  removeBgLoading,
  setRemoveBgLoading,
}) => {
  const [copied, setCopied] = useState(false);
  const [promptHover, setPromptHover] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(image.title);
    setCopied(true);
  };

  const toggleProcessedImage = async () => {
    if (!image.otherUrl) {
      await processImage();
    } else {
      const newImage = {
        url: image.otherUrl,
        otherUrl: image.url,
        withBg: !image.withBg,
        title: image.title,
      };
      updateImage(index, newImage);
    }
  };

  const processImage = async () => {
    setRemoveBgLoading(true);
    try {
      const formData = new FormData();
      formData.append('image', await convertToBlob(image.url));

      const response = await axios.post(
        (process.env.NODE_ENV === "production" && window.location.origin === "https://tazzu.net")
          ? "https://us-central1-aiclothing.cloudfunctions.net/app/remove-background"
          : "http://localhost:5001/aiclothing/us-central1/app/remove-background", // "http://localhost:8000/remove-background",
        formData,
        {
          responseType: "blob",
          headers:
          {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = URL.createObjectURL(blob);
        const newImage = {
          url: url,
          otherUrl: image.url,
          withBg: false,
          title: image.title,
        };
        updateImage(index, newImage);
      } else {
        console.error("Couldn't remove background of image:", response.error);
      }
    } catch (err) {
      console.error(err);
    }
    setRemoveBgLoading(false);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={closeModal}
      ></div>
      <div className="relative flex flex-col bg-slate-200 px-4 py-2 pb-4 rounded-lg shadow-lg max-w-[90%] md:max-w-[450px] md:max-h-[90vh] overflow-scroll">
        <div
          className={`flex flex-row ${image.title && !image.title.includes("Tazzu Logo")
            ? "justify-between"
            : "justify-end"
            } mb-2`}
        >
          {image.title && !image.title.includes("Tazzu Logo") && (
            <button
              onClick={() => toggleProcessedImage()}
              className={`bg-white rounded-xl p-1 px-2 hover:bg-slate-100 mr-2 ${removeBgLoading ? "bg-slate-100" : ""
                }`}
              disabled={removeBgLoading}
            >
              <div className="flex items-center">
                <FaMagic
                  className="justify-end  mr-2 hover:cursor-pointer"
                  size={15}
                  onClick={closeModal}
                />
                <span className="font-bold">
                  {image.withBg ? "Remove Background" : "Undo Background"}
                </span>
              </div>
            </button>
          )}
          <AiFillCloseCircle
            className="justify-end mb-1 hover:cursor-pointer"
            size={24}
            onClick={closeModal}
          />
        </div>
        {removeBgLoading ? (
          <div className="relative w-full h-full">
            <img
              src={image.url}
              alt="Modal Image"
              className={`h-full w-full max-w-full max-h-full ${image.title ? '' : 'object-contain'} ${!image.withBg ? "" : "bg-" + convertColor(selectedColor)
                } opacity-50`}
            />
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <ReactLoading type="spin" color="black" height={48} width={48} />
            </div>
          </div>
        ) : (
          <img
            src={image.url}
            alt="Modal Image"
            className={`h-full w-full max-w-full max-h-full ${image.title ? '' : 'object-contain'} ${!image.withBg ? "" : "bg-" + convertColor(selectedColor)
              }`}
          />
        )}

        <span className="font-semibold mt-2">Placement</span>
        <div className="flex flex-row items-start justify-around md:justify-center md:gap-10 bg-slate-300 rounded-xl p-2 my-1 mb-2">
          {["logo", "front", "back"].map((side) => {
            var current = stamp[side] && stamp[side] !== images[selectedImageIndex].url
              ? images.findIndex((image) => image.url === stamp[side]) + 1
              : ""
            if (
              selectedType !== "Tote-bag" ||
              (selectedType === "Tote-bag" && side === "front")
            ) {
              return (
                <div>
                  <div className="flex flex-row">
                    <label
                      key={side}
                      className={`flex items-center gap-2 cursor-pointer`}
                    >
                      <input
                        type="checkbox"
                        className="scale-[1.3]"
                        checked={stamp[side] === images[selectedImageIndex].url}
                        onChange={() => toggleStamp(side)}
                      />
                      <span>{side.replace(/^./, side[0].toUpperCase())}</span>
                    </label>
                  </div>
                  {current && <span className="mt-2 text-xs text-orange-400">Selected: {current}</span>}
                </div>
              );
              return (
                <Toggle
                  key={side}
                  checked={stamp[side] === images[selectedImageIndex].url}
                  current={
                    stamp[side] &&
                      stamp[side] !== images[selectedImageIndex].url
                      ? images.findIndex((image) => image.url === stamp[side]) +
                      1
                      : ""
                  }
                  onChange={() => toggleStamp(side)}
                  label={"Stamp " + side}
                  color={selectedColor}
                />
              );
            }
            return <></>;
          })}
        </div>

        {image.title && !image.title.includes("Tazzu Logo") && (
          <>
            <span className="font-semibold mt-2">Prompt</span>
            <div
              className="flex flex-inline items-center justify-between gap-1 cursor-pointer"
              onClick={copyToClipboard}
              onMouseEnter={() => setPromptHover(true)}
              onMouseLeave={() => setPromptHover(false)}
            >
              <span>{image.title}</span>
              <div
                className={`flex items-center justify-center cursor-pointer border border-2${copied
                  ? "bg-green-700 text-white border-green-700"
                  : promptHover
                    ? "border-black bg-black text-white"
                    : "border-black bg-transparent"
                  } rounded p-1`}
              >
                {copied ? (
                  <BsFillClipboard2CheckFill
                    size={20}
                    onClick={copyToClipboard}
                  />
                ) : (
                  <BsFillClipboard2Fill size={20} onClick={copyToClipboard} />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
